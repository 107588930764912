import type { FormItemError } from '@/services/api'

export function processApiAndCartErrors(error: any, page = 'any') {
  let errors: FormItemError[] = []

  if (error.status === 500) {
    errors.push({
      message:
        'Something went terribly wrong - please Try again. If the condition persists, please contact support'
    })
    return errors
  }

  // special case for the login page, because we want to be able to catch the case of a
  // participant that hasn't verified their account yet!
  if (page === 'login') {
    if (error.status === 401) {
      errors.push({
        message: 'Invalid login credentials'
      })
    } else if (error.status === 403) {
      if (error.error.error) {
        errors.push({
          message: error.error.error
        })
      }
      if (error.error.message) {
        errors.push({
          message: error.error.message
        })
      }
      if (error.error.data['resend_verification_link']) {
        errors.push({
          message: error.error.data['resend_verification_link']
        })
      }
    }

    return errors
  }

  switch (error.status) {
    case 401:
      errors.push({
        message: "You're not logged in"
      })
      break
    case 403:
      errors.push({
        message: "You're not authorized to be here"
      })
      break
  }

  if (error.error.message) {
    if (typeof error.error.message === 'string') {
      errors.push({
        message: error.error.message
      })
    } else {
      errors = [...errors, ...error.error.message]
    }
  }

  return errors
}
