import { ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useGlobalStore } from '@/stores/global'
import { CONFIG } from '@/config'
import type { Document } from '@/services/api'
import { useDocument } from '@/composable/useDocument'

export function useDocuSign() {
  const userStore = useUserStore()
  const globalStore = useGlobalStore()
  const { fetchDocumentUrl, markDocumentSigned } = useDocument()
  const account = userStore.account

  const clientId = CONFIG.DOCUSIGN_CLIENTID // Assign the client ID here
  const signingStarted = ref<boolean>(false)

  const loadDocuSignScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = CONFIG.DOCUSIGN_JS_URL // Assign the javascript file for docusign here
      script.onload = () => resolve()
      script.onerror = () => reject(new Error('Failed to load the DocuSign script'))
      document.head.appendChild(script)
    })
  }

  function processEvent(event: string, document: Document) {
    switch (event) {
      case 'signing_complete':
        globalStore.addSnackbarMessageToQueue('Document successfully signed', 'success')
        markDocumentSigned(document)
        break
      case 'viewing_complete':
        break
      default:
        // Handle unknown or unhandled session end type
        globalStore.addSnackbarMessageToQueue('Document signing cancelled', 'error')
        break
    }
  }

  const loadDocuSign = async (signingUrl: string, document: Document) => {
    // Exit if the signingUrl is not set
    if (!signingUrl) {
      console.error('Signing URL not set')
      throw new Error('Signing URL not set')
    }

    const signingConfiguration = {
      url: signingUrl,
      displayFormat: 'focused',
      style: {
        branding: {
          primaryButton: {
            backgroundColor: 'magenta',
            color: 'white'
          }
        },
        signingNavigationButton: {
          finishText: 'Agreed!',
          position: 'bottom-center'
        }
      }
    }

    try {
      globalStore.incrementLoading()
      const docuSign = await (window as any).DocuSign.loadDocuSign(clientId)
      const signing = docuSign.signing(signingConfiguration)
      /** Event handlers **/
      signing.on('ready', () => {
        if (document.signed_by == null) {
          signingStarted.value = true
        }
      })
      signing.on('sessionEnd', (event: { sessionEndType: any }) => {
        processEvent(event.sessionEndType, document)
        signingStarted.value = false
      })
      signing.mount('#signing-ceremony')
    } catch (error) {
      // Any configuration or API limits will be caught here
      console.error(error)
    } finally {
      globalStore.decrementLoading()
    }
  }

  const signDocument = async (document: Document) => {
    globalStore.incrementLoading()

    try {
      if (account) {
        const signingUrl = await fetchDocumentUrl(document)
        if (signingUrl) {
          await loadDocuSign(signingUrl, document)
        } else {
          console.error('Failed to retrieve signing URL.')
        }
      } else {
        console.error('No account information available.')
      }
    } catch (error) {
      console.error('Error during document signing process:', error)
    } finally {
      globalStore.decrementLoading()
    }
  }

  return { loadDocuSignScript, signDocument, signingStarted }
}
