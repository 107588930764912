import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useGlobalStore } from '@/stores/global'
import { routerPush } from '@/router'
import { api, isFetchError } from '@/services'
import type { Document } from '@/services/api'

const documents = ref<Document[]>([])

export function useDocument() {
  const userStore = useUserStore()
  const globalStore = useGlobalStore()
  const account = userStore.account

  if (!account) {
    throw new Error('Account not found. Composable cannot proceed.')
  }

  const unsignedDocuments = computed(() => {
    return documents.value.filter((doc) => doc.signed_by === null)
  })

  const signedDocuments = computed(() => {
    return documents.value.filter((doc) => doc.signed_by !== null)
  })

  const unsignedDocumentsCount = computed(() => {
    return unsignedDocuments.value.length
  })

  const markDocumentSigned = (document: Document) => {
    if (account) {
      const signedDocuments = documents.value.filter((doc) => doc.envelope === document.envelope)
      signedDocuments.forEach((doc) => {
        doc.signed_by = userStore.fullname()
      })
      userStore.updateDocumentsToSignCount(documents.value)

      // Send a patch request -- but do not wait for response
      api.api.setDocumentSigned(account.id, document.id).catch(() => {
        // console.error('Error marking document as signed:', error)
      })
    }
  }

  const fetchDocuments = async (): Promise<void> => {
    try {
      const documentResponse = await api.api.getDocuments(account.id)
      if (documentResponse.data.data) {
        const newDocuments = documentResponse.data.data
        newDocuments.sort((a, b) => {
          if (!a.signed_by && b.signed_by) {
            return -1 // a comes before b
          }
          if (a.signed_by && !b.signed_by) {
            return 1 // b comes before a
          }
          return 0 // no change
        })
        documents.value = newDocuments
        userStore.updateDocumentsToSignCount(documents.value)
      }
    } catch (error) {
      if (isFetchError(error) && error.status === 401) {
        await routerPush('login')
        throw new Error('Unauthorized or token expired')
      }
    }
  }

  const fetchDocumentUrl = async (document: Document) => {
    globalStore.incrementLoading()
    try {
      const response = await api.api.getDocumentUrl(account.id, document.id)
      const signingUrl = response.data?.data?.url
      return signingUrl
    } catch (error) {
      console.error('Error fetching document URL:', error)
    } finally {
      globalStore.decrementLoading()
    }
  }

  return {
    documents,
    unsignedDocuments,
    signedDocuments,
    unsignedDocumentsCount,
    markDocumentSigned,
    fetchDocuments,
    fetchDocumentUrl
  }
}
