// import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from '@/router'

import resetStore from '@/plugins/resetStore'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import vuetify from '@/plugins/vuetify'

import * as Sentry from '@sentry/vue'

// https://vue3datepicker.com/props/modes/#timezone
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import LoadScript from 'vue-plugin-load-script'
import { CONFIG } from '@/config'

const app = createApp(App)
app.component('VueDatePicker', VueDatePicker)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(resetStore)

app.use(pinia)
app.use(vuetify)
app.use(router)
app.use(LoadScript)

Sentry.init({
  app,
  dsn: `${CONFIG.SENTRY_DSN}`,
  environment: `${CONFIG.RUNNING_IN_ENV}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', CONFIG.API_BASE_URL, /fieldhockeybc.ca/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
