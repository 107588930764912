<script setup lang="ts">
import { defineProps, ref, watchEffect } from 'vue'
import type { ParticipantNotification } from '@/services/api'
import { formatDateLong } from '@/composable/formatDate'
import { markNotificationRead } from '@/utils/helpers-participant'
import { useUserStore } from '@/stores/user'

interface Props {
  notification: ParticipantNotification
}

const userStore = useUserStore()

const props = defineProps<Props>()
const notif = ref(props.notification)

watchEffect(() => {
  notif.value = props.notification
})

function markAsRead() {
  if (notif.value.participant && notif.value.id) {
    markNotificationRead(notif.value.participant, notif.value.id)
    userStore.setNotificationToRead(props.notification)
  }
}

// const link_text = notif.value.data?.link_label ?? 'Click'
</script>
<template>
  <v-list-item>
    <div class="d-flex flex-row">
      <div class="flex-fill">
        <v-list-item-title>
          {{ notif?.data?.message }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ notif.created_at ? formatDateLong(notif.created_at) : '' }}
          <template v-if="notif.read_at"><br />Read: {{ formatDateLong(notif.read_at) }}</template>
        </v-list-item-subtitle>
      </div>
      <div class="flex-1">
        <v-list-item-action v-if="!notif.read_at"
          ><v-btn v-if="!notif.read_at" @click="markAsRead" outline>
            Mark Read
          </v-btn></v-list-item-action
        >
      </div>
    </div>
  </v-list-item>

  <!--  <v-list-item>-->
  <!--    <v-list-item-title class="font-weight-bold">{{ notif.data?.message }}</v-list-item-title>-->
  <!--    <v-list-item-subtitle>{{ notif.participant_name }}</v-list-item-subtitle>-->
  <!--    <v-list-item-subtitle-->
  <!--      >Sent: {{ notif.created_at ? formatDateLong(notif.created_at) : 'n/a' }}</v-list-item-subtitle-->
  <!--    >-->
  <!--    <v-list-item-subtitle v-if="notif.read_at"-->
  <!--      >Read: {{ formatDateLong(notif.read_at) }}</v-list-item-subtitle-->
  <!--    >-->
  <!--    <template v-slot:append>-->
  <!--      <v-btn v-if="!notif.read_at" @click="markAsRead" color="grey-lighten-1" variant="text">-->
  <!--        Mark Read-->
  <!--      </v-btn>-->
  <!--    </template>-->
  <!--  </v-list-item>-->
</template>
