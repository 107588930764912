<script setup lang="ts">
import logo from '@/assets/FHBC-logo.svg'

const copyrightYear = new Date().getFullYear()
</script>
<template>
  <v-container class="footer">
    <v-row>
      <v-col cols="6" cols-sm="12" class="text-md-right text-center">
        <span class="float-md-end align-center text-center">
          <span class="d-none d-lg-inline d-xl-inline"
            ><img :src="logo" alt="logo" class="footer-logo" /> <br />&copy;&nbsp;{{ copyrightYear
            }}<br />
          </span>
          <span class="d-inline d-lg-none d-xl-none">
            604.737.3146&nbsp;(t)<br />604.873.6488&nbsp;(f) <br /><a
              href="https://fieldhockeybc.com/about/contact-us/"
              target="_blank"
              rel="noopener"
              >Contact Emails</a
            >
          </span>
        </span>
      </v-col>
      <v-col cols="6" cols-sm="12" class="text-md-left text-center">
        7455&nbsp;132nd&nbsp;St.,&nbsp;Suite&nbsp;101
        <br />Surrey, BC, V3W&nbsp;1J8
        <span class="d-none d-lg-inline d-xl-inline"
          ><br />604.737.3146&nbsp;(t) 604.873.6488&nbsp;(f) <br /><a
            href="https://fieldhockeybc.com/about/contact-us/"
            target="_blank"
            rel="noopener"
            >Contact Emails</a
          ></span
        ><span class="d-inline d-lg-none d-xl-none"><br />&copy;&nbsp;{{ copyrightYear }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss" scoped>
.footer {
  font-size: 12px;
  .footer-logo {
    width: 50px;
    height: auto;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Custom media query for screens smaller than 400px */
@media (max-width: 425px) {
  .custom-flex-row > .v-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
