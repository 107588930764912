<script setup lang="ts">
import { CONFIG } from '@/config'
import { defineProps, watch } from 'vue'

const assetsDir = CONFIG.SITE_ASSET_ROOT_PATH
const emit = defineEmits(['update:modalValue'])

interface Props {
  modalValue: boolean
}

const props = defineProps<Props>()
let dialog = props.modalValue ?? false

// modal setup
watch(
  () => props.modalValue,
  (newVal) => {
    dialog = newVal
  }
)

function close() {
  emit('update:modalValue', false)
}
</script>
<template>
  <v-dialog v-model="dialog" class="dialog-modal">
    <v-card class="mx-auto px-6 py-8 dialog-card" rounded>
      <v-card-title class="headline grey lighten-2" primary-title>
        Registration System “How to” Guide
      </v-card-title>

      <v-card-text>
        <h3 class="red text-uppercase">IMPORTANT NOTES:</h3>
        <ul>
          <li>
            This system allows people to either register themselves or to add the person that you
            are looking to register. This may be yourself or your children.
            <ul>
              <li>Participant = the person in control of the account. There will only be one.</li>
              <li>
                Dependant = children/relative(s) that you are registering on behalf of. There can be
                multiple.
              </li>
            </ul>
          </li>
          <li>
            <strong class="red"><u>The text/formatting is a work in progress.</u></strong>
          </li>
          <li>
            <strong class="red"><u>*** Do not forget to sign the waivers ***</u></strong> This step
            happens <strong>AFTER the payment</strong>, and is required to have a valid
            registration.
          </li>
        </ul>

        <h3 class="text-uppercase">Step 1: Create Account</h3>
        <p>
          <strong>Registration Page:</strong>
          <br /><a href="https://www.fieldhockeybc.ca/">https://www.fieldhockeybc.ca/</a>
        </p>
        <ul>
          <li>
            <strong>You will need to create a new account for 2024/25.</strong> Your credentials
            from the previous registration systems do not automatically carry over.
          </li>
        </ul>
        <p>
          <v-img :src="`${assetsDir}/assets/help/log-me-in.png`" />
        </p>
        <ul>
          <li>
            Once you enter an email and create the password, you will receive an email confirmation.
            You must click the link in the email to proceed with registration.
          </li>
        </ul>

        <v-divider class="my-6" />

        <h3 class="text-uppercase">Step 2: Link <em>or</em> Create a Participant / Dependant</h3>
        <ul>
          <li>
            Once you have confirmed your account via email, next step is to add the person
            (participant/dependant) that you are looking to register.
          </li>
          <li>
            Once you have confirmed your account's email address by clicking the link in the
            confirmation email, you will need to log into your account.
            <ul>
              <li>
                <strong><u>Please scroll down to the bottom of the page</u></strong
                >. You should see something similar to the image below:
              </li>
            </ul>

            <br /><v-img :src="`${assetsDir}/assets/help/link-create-participant.png`" />
          </li>

          <li>
            If you are registering <u>yourself</u> and you participated in previous seasons, click
            <strong class="">Link Existing Participant.</strong>
            <ul>
              <li>
                If you are registering <u>yourself</u> and you have not participated in previous
                seasons, click <strong class="">Create New Participant.</strong>
              </li>
              <li>
                If you are registering a <u>child/relative</u>, click on the
                <v-btn color="fhbcRed" size="x-small"
                  ><v-icon icon="mdi-account-plus"></v-icon
                ></v-btn>
                icon beside the "Dependants" title in the sidebar. You will be able to find an
                existing record, or create a new one.
              </li>
            </ul>
          </li>
          <li>
            For the <strong>Create New</strong> function, please enter all mandatory fields and
            submit <strong class="red">NOTE:</strong> there are 4 tabs, scroll left to right.
          </li>
          <li>
            For the <strong>Link Existing</strong> function, mandatory fields are name and
            birthdate. Enter those and click on Find Participants. Scroll down and find yourself on
            the list.
            <ul>
              <li>If for yourself, click <v-btn size="x-small" color="success">MINE</v-btn>.</li>
              <li>
                If you are linking a dependant, click
                <v-btn color="primary" size="x-small"><nobr>I’M A GUARDIAN</nobr>.</v-btn>

                <br />&nbsp;<br />
                <v-img :src="`${assetsDir}/assets/help/find-participants.png`" />
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <em
            ><span class="red">***</span> Linking a participant/dependant to your account will
            generate another email confirmation. (To confirm that it is actually you/your relative)
            You cannot proceed until you click the link in your email.&nbsp;</em
          >
        </p>
        <p>
          <v-img :src="`${assetsDir}/assets/help/email-confirmation.png`" />
        </p>

        <v-divider class="my-6" />

        <h3 class="text-uppercase">Step 3: Adding your Annual Registration</h3>

        <ul>
          <li>
            The participant/ dependant should now show up on your account on the right hand side of
            the page.
          </li>
          <li>
            <em
              >If it does not show up, please try logging out and logging back into your
              account.</em
            >
            <br />&nbsp;<br />
            <v-img :src="`${assetsDir}/assets/help/dependants-.png`" />
          </li>
        </ul>
        <ul>
          <li>Click on the dependant/participant name.</li>
          <li>Scroll down to the bottom of the page to access the registrations.</li>
          <li>Click <strong>Add Registration to Cart.</strong></li>
          <li>Select the appropriate registration category and hit <strong>Submit.</strong></li>
          <li>
            This should take you to your cart.
            <ul>
              <li>
                <em
                  >Note that you can add multiple registrations for multiple dependants to the same
                  cart. Repeat the above process to add registrations for other individuals if
                  applicable.</em
                >
              </li>
              <li>Click <strong>Proceed to Final Review and Checkout.</strong></li>
              <li>To proceed with payment, click <strong>Checkout.</strong></li>
              <li>Enter your credit card info and click <strong>Submit Payment.</strong></li>
              <li>You should receive an email receipt for your payment.</li>
            </ul>
          </li>
        </ul>

        <v-divider class="my-6" />

        <h3 class="text-uppercase">Step 4: Signing the Waivers</h3>
        <ul>
          <li>
            Once you have completed your payment, you must complete the waiver forms for your
            registration to be considered valid. Your account should prompt you to sign, if it
            doesn’t please refresh the page.
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
@import '@/styles/style';

h1 {
  line-height: normal;
}

.red {
  color: $fhbc-red;
}

ul {
  padding-left: 20px;
}
</style>
