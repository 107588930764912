import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
  type RouteParams,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from 'vue-router'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'
import { useConfigStore } from '@/stores/config'

export type AppRouteNames =
  | 'home'
  | 'about'
  | 'create-account'
  | 'login'
  | 'find-participant'
  | 'participant-view'
  | 'cart'
  | 'checkout'
  | 'notifications'
  | 'documents'
  | 'email-verify'
  | 'password-reset'
  | 'maintenance'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: 'Field Hockey BC Registration'
    }
  },
  {
    path: '/account/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'FHBC Login'
    }
  },
  {
    path: '/account/create',
    name: 'create-account',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'FHBC Login'
    }
  },
  // {
  //   path: '/logout',
  //   name: 'logout',
  //   component: {
  //     beforeRouteEnter(to, from, next) {
  //       console.log({ from });
  //       logout()
  //       next('home');
  //     }
  //   }
  // },
  {
    path: '/find-participant',
    name: 'find-participant',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Connect Participants'
    }
  },
  {
    path: '/participant/:participantId',
    name: 'participant-view',
    component: () => import('@/views/ParticipantView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Participant'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/NotificationsView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Account Notifications'
    }
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/DocumentsDisplay.vue'),
    meta: {
      requiresAuth: true,
      title: 'Account Documents'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/CartView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Cart'
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/CheckoutView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Checkout'
    }
  },
  {
    path: '/email/verify/:accountId/:code',
    name: 'email-verify',
    component: () => import('@/views/EmailVerificationView.vue'),
    meta: {
      title: 'Email Verification'
    }
  },
  {
    path: '/participant/verify/:participantId',
    name: 'participant-verify',
    component: () => import('@/views/AttachVerificationView.vue'),
    meta: {
      title: 'Email Verification'
    }
  },
  {
    path: '/reset-password',
    name: 'password-reset',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'Password Reset'
    }
  },
  {
    path: '/resend-verification-email',
    name: 'resend-verify',
    component: () => import('@/views/ResendVerificationView.vue'),
    meta: {
      title: 'Resend Verification Email'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/MaintenanceView.vue'),
    meta: {
      title: 'Under Maintenance'
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: 'Help Page'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/PageNotFoundView.vue'),
    meta: {
      title: 'Page Not Found'
    }
  }
]

// export const routes: RouteRecordRaw[] = [
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    // if there is a banner image, scroll to below that on content change
    //const bannerImage = document.querySelector('.banner-image') as HTMLElement | null
    const scrollToPosition = 0
    return {
      top: scrollToPosition,
      behavior: 'smooth'
    }
  }
})

export function logout() {
  const userStore = useUserStore()
  userStore.logout()
}

export function routerPush(
  name: AppRouteNames,
  params?: RouteParams
): ReturnType<typeof router.push> {
  return params === undefined ? router.push({ name }) : router.push({ name, params })
}

// Global before guards
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards}
router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const globalStore = useGlobalStore()
    const userStore = useUserStore()
    const configStore = useConfigStore()

    if (configStore.appUnderMaintenance && to.name !== 'maintenance') {
      globalStore.addSnackbarMessageToQueue('The site is currently in maintenance mode.', 'error')
      next({ name: 'maintenance' })
      return
    }

    globalStore.incrementLoading()

    // Show Loading
    // comment out for https://github.com/logue/vite-vuetify-ts-starter/issues/16
    // globalStore.setLoading(true);
    globalStore.setMessage('')
    // if the route is a guarded route - then we should check to see if they're logged in ..
    if (to.meta.requiresAuth && !userStore.isLoggedin) {
      next({ name: 'login', query: { redirect: to.path } })
    } else {
      next()
    }

    // Hide snack bar
  }
)

// Global After Hooks
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks}
router.afterEach((to) => {
  const globalStore = useGlobalStore()
  // Hide Loading
  globalStore.decrementLoading()
  document.title = to.meta.title ?? import.meta.env.VITE_APP_TITLE
})

/*
const scrollBehavior = async (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  savedPosition: RouteLocation
): Promise<any> => {
  let scrollpos = {};
  if (to.hash) {
    scrollpos = {
      el: to.hash,
      behavior: 'smooth',
    };
  } else if (savedPosition) {
    scrollpos = savedPosition;
  } else {
    scrollpos = { top: 0 };
  }
  return await new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(scrollpos);
    }, 600);
  });
};
*/

export default router
