<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import type { Document } from '@/services/api'

import { formatDate } from '@/composable/formatDate'
import { useDocument } from '@/composable/useDocument'
import DocuSignModal from '@/components/documents/DocuSignModal.vue'

interface Props {
  modalValue: boolean
}

const props = defineProps<Props>()
let dialog = props.modalValue ?? true
const emit = defineEmits(['update:modalValue'])

const document = ref<Document | null>(null)
const { unsignedDocuments, signedDocuments, fetchDocuments } = useDocument()

await fetchDocuments()

const tab = ref(signedDocuments.value.length ? 0 : 1)
const filteredDocuments = computed(() => {
  return tab.value === 1 ? signedDocuments.value : unsignedDocuments.value
})
const selectedDocument = ref<Document | null>(filteredDocuments.value[0] ?? null)

function processDocument(doc: Document) {
  document.value = doc
}

// Using watch because we want to manually update selectedDocument as well
watch(filteredDocuments, () => {
  selectedDocument.value = filteredDocuments.value[0]
})

watch(
  () => props.modalValue,
  (newVal: boolean, oldVal: boolean) => {
    if (newVal !== oldVal) {
      dialog = newVal

      if (newVal) {
        fetchDocuments()
        tab.value = signedDocuments.value.length ? 0 : 1
      }
    }
  }
)

function signingConcluded() {
  document.value = null
}

function close() {
  emit('update:modalValue', false)
}
</script>
<template>
  <v-dialog v-model="dialog" class="dialog-modal-large">
    <v-container>
      <DocuSignModal :document="document" @signingConcluded="signingConcluded" />

      <v-card>
        <v-card-title class="text-center justify-center py-6"><h2>Documents</h2></v-card-title>
        <v-btn-toggle v-model="tab" mandatory class="d-flex justify-space-between">
          <v-btn
            :value="0"
            class="full-width-btn"
            :class="{ 'active-tab': tab === 0, 'inactive-tab': tab !== 0 }"
          >
            Unsigned
            <v-badge
              :content="unsignedDocuments.length"
              inline
              color="error"
              v-if="unsignedDocuments.length > 0"
            ></v-badge>
          </v-btn>
          <v-btn
            :value="1"
            class="full-width-btn"
            :class="{ 'active-tab': tab === 1, 'inactive-tab': tab !== 1 }"
          >
            Signed
          </v-btn>
        </v-btn-toggle>

        <v-divider></v-divider>
        <v-row>
          <v-col cols="4" class="scrollable-list">
            <template v-for="(doc, idx) in filteredDocuments" :key="idx">
              <v-sheet
                class="clickable-row"
                @click="selectedDocument = doc"
                :class="{
                  'alternate-row': idx % 2 === 1,
                  'selected-row': selectedDocument?.id === doc.id
                }"
              >
                <v-row no-gutters>
                  <v-col class="d-flex justify-start" cols="12">
                    {{ doc.document }}
                  </v-col>
                </v-row>
              </v-sheet>
              <v-divider v-if="idx < filteredDocuments.length - 1"></v-divider>
            </template>
          </v-col>
          <v-col cols="8">
            <v-card class="mx-auto my-4 pa-4" v-if="selectedDocument">
              <v-row justify="center">
                <v-col class="text-center">
                  <p class="text-h6 font-weight-bold mb-4">
                    {{ selectedDocument.document }}
                  </p>
                  <v-divider></v-divider>
                  <br />
                  <p class="mb-4 text-body-2">
                    {{ selectedDocument.participant_name }}<br />
                    {{ selectedDocument.product_name }} ({{ selectedDocument.product_type }})<br />
                  </p>
                  <p class="mt-4 text-body-2">
                    <strong>Created:</strong> {{ formatDate(selectedDocument.created) }}
                    <template v-if="selectedDocument.signed_by">
                      <br /><strong>Signed by:</strong> {{ selectedDocument.signed_by }}
                    </template>
                  </p>
                </v-col>
              </v-row>
              <div class="v-card-actions justify-center">
                <v-btn
                  v-if="selectedDocument.id"
                  @click="processDocument(selectedDocument)"
                  color="fhbcGreen"
                  :variant="selectedDocument.signed_by ? 'outlined' : 'flat'"
                >
                  {{ selectedDocument.signed_by ? 'View Document' : 'Sign Document' }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="close()"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<style lang="scss" scoped>
@import '@/styles/style';
.scrollable-list {
  max-height: 400px;
  overflow-y: auto;
  padding-top: 0px;
  margin-top: 15px;
}

.clickable-row.selected-row {
  background-color: $fhbc-green;
  color: white;
  margin-left: -4px;
  padding-left: 16px;
}

.clickable-row {
  padding: 10px;
  cursor: pointer;
  background-color: white;
}

.clickable-row:hover {
  background-color: #f5f5f5;
}

.clickable-row.selected-row:hover {
  background-color: $fhbc-green;
}

.alternate-row {
  background-color: #f9f9f9;
}

.v-card-actions {
  justify-content: center;
}
</style>
