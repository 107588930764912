/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Type of products to purchase */
export enum ProductType {
  RegistrationOption = 'RegistrationOption',
  Course = 'Course',
  Program = 'Program'
}

/**
 * Generic Error
 * Generic Error Model Structure
 */
export interface GenericErrorModel {
  /** Error message */
  message?: string
  errors?: {
    details?: string[]
  }
}

/**
 * Account Verification Error
 * Account Verification Error Structure. Account has not been verified through email
 */
export interface AccountVerifiedError {
  /** @example "Account not verified" */
  error?: string
  /** @example "Please verify your email to access your account." */
  message?: string
  data?: {
    /** @example "https://example.com/resend-verification" */
    resend_verification_link?: string
  }
}

/**
 * Cart Item Error
 * Cart Item Error Structure
 */
export interface CartItemError {
  /** Index of the item within the cart */
  item?: number
  /** The attribute the error belongs to */
  attribute?: string
  /** The error message associated with the  */
  message?: string
}

/**
 * Form Item Error
 * Form Item Error Structure
 */
export interface FormItemError {
  /** Index of the field within the form */
  item?: number
  /** The attribute the error belongs to */
  attribute?: string
  /** The error message associated with the  */
  message?: string
}

/**
 * Refresh Login
 * Account Login Object
 */
export interface RefreshAccount {
  /**
   * @format token
   * @example "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9"
   */
  refreshToken: string
}

/**
 * Forgot Password Request
 * Initial Request to reset account password
 */
export interface ForgotPasswordRequest {
  /**
   * @format email
   * @example "user@example.com"
   */
  email: string
}

/**
 * Forgot Password Request
 * Initial Request to reset account password
 */
export interface ResetPasswordRequest {
  /**
   * @format token
   * @example "reset_token_here"
   */
  token: string
  /**
   * @format email
   * @example "user@example.com"
   */
  email: string
  /**
   * @format password
   * @example "newSecurePassword123"
   */
  password: string
  /**
   * @format password
   * @example "newSecurePassword123"
   */
  password_confirmation: string
}

/**
 * SportsPay Api Key
 * API Key for  SportsPay checkout
 */
export interface SportsPayApiKey {
  /** Unique identifier of the Club */
  status?: string
  /** KEYGENERATED, or the error message */
  message?: string
  /** The user fee for the transaction) */
  userFee?: number
  /** The APIKey for your browser to use) */
  apiKey?: string
  /** The Host name used to load the Javascript and to pass to the Tokenize */
  host?: string
  /**
   * Gateway time
   * @format date
   */
  date?: string
  /**
   * Gateway time
   * @format time
   */
  time?: string
  /**
   * The amount of time the gateway required to process the request
   * @format float
   */
  duration?: number
}

/**
 * Authorization
 * Authorization Structure
 */
export interface AuthStruct {
  /** @example "new_access_token_here" */
  access_token?: string
  /** @example "bearer" */
  token_type?: string
  /** @example 3600 */
  expires_in?: number
  /** @example 1705097237 */
  expiry_ts?: number
}

export interface CartItemRequest {
  /** Form data for submiting a cart item */
  data?: CartItem
}

export interface CartRequest {
  items?: CartItem[]
  /** Cart Validation Token */
  cart_validation_token?: string
  /** OTT for payment processing */
  payment_ott?: string
  billing?: Billing
}

export interface CartValidationRequest {
  items?: CartItem[]
}

/**
 * Cart Item
 * Form data for submiting a cart item
 */
export interface CartItem {
  /** ID of the product to purchase */
  product: number
  /** Quantity of the product to purchase */
  quantity: number
  /** Type of products to purchase */
  product_type: ProductType
  /** ID of the payment option */
  payment_option: number
  /** ID of the participant to purchase for */
  participant: number
  /** Answers to additional signup questions */
  answers?: QuestionAnswer[]
}

export interface Billing {
  /** Full name of the billing contact */
  full_name: string
  /** Billing address line 1 */
  address: string
  /** Billing address line 2 (optional) */
  address2?: string | null
  /** City of the billing address */
  city: string
  /** State or province (2-letter code) */
  province: string
  /**
   * Postal or ZIP code
   * @pattern ^(?:[A-Z]\d[A-Z]\s?\d[A-Z]\d|\d{5}(?:[-\s]\d{4})?)$
   */
  postal_code: string
  /** Country (2-letter code) */
  country: string
}

/**
 * Question Answer
 * Answer to a signup question
 */
export interface QuestionAnswer {
  /** ID of the question answered */
  question_id: number
  /** The answer */
  answer: string
}

/**
 * Account Login
 * Account Login Object
 */
export interface LoginAccount {
  /**
   * @format email
   * @example "account@example.com"
   */
  email: string
  /**
   * @format password
   * @example "password123"
   */
  password: string
}

/**
 * Lookup Participant Request
 * Request body to search on Participant model
 */
export interface LookupParticipantRequest {
  /** Unique participant number */
  participant_number?: number
  /**
   * Email of the participant
   * @format email
   * @example "account@example.com"
   */
  email?: string
  /** Last name of the participant */
  last_name?: string
  /** First name of the participant */
  first_name?: string
  /**
   * Date of birth of the participant
   * @format date
   */
  date_of_birth?: string
  /** Name of the participant's club */
  club_name?: string
  /**
   * last season of registration
   * @pattern ^\d{4}-\d{4}$
   * @example "2023-2024"
   */
  last_season_of_registration?: string
}

/**
 * Store Account Request
 * Request body to store a Account model
 */
export interface StoreAccountRequest {
  /** First Name */
  first_name?: string
  /** Last Name */
  last_name?: string
  /**
   * @format email
   * @example "account@example.com"
   */
  email?: string
  /**
   * @format password
   * @example "password123"
   */
  password?: string
  /**
   * @format password
   * @example "password123"
   */
  password_confirmation?: string
  /**
   * @format password
   * @example "password123"
   */
  current_password?: string
}

/**
 * Store Participant Request
 * Request body to store a Participant model
 */
export interface StoreParticipantProfileRequest {
  /** @example "John" */
  guardian1_first_name: string
  /** @example "Doe" */
  guardian1_last_name: string
  /**
   * @format email
   * @example "john.doe@example.com"
   */
  guardian1_email: string
  /** @pattern ^\d{3}-\d{3}-\d{4}$ */
  guardian1_phone: string
  /** @example "Jane" */
  guardian2_first_name?: string | null
  /** @example "Doe" */
  guardian2_last_name?: string | null
  /**
   * @format email
   * @example "jane.doe@example.com"
   */
  guardian2_email?: string | null
  /** @pattern ^\d{3}-\d{3}-\d{4}$ */
  guardian2_phone?: string
  /** @example "Additional notes" */
  participant_profilecol?: string | null
}

/**
 * Store Participant Request
 * Request body to store a Participant model
 */
export interface StoreParticipantRequest {
  /** The account ID associated with this participant */
  account_id?: number
  /** The guardian's account ID, if applicable */
  guardian_account_id?: number
  /** @format email */
  email: string
  last_name: string
  first_name: string
  /** Nullable */
  middle_name?: string
  /** Nullable */
  preferred_name?: string
  /** Nullable */
  language?: string
  /**
   * @format date
   * @example "2000-01-01"
   */
  date_of_birth: string
  gender: string
  /** The club ID associated with this participant */
  club_id?: number
  /** The league ID associated with this participant */
  league_id?: number
  /** Nullable */
  aboriginal_ancestry?: string
  /** Nullable */
  province_of_registration?: string
  address: string
  /** Nullable */
  address2?: string
  city: string
  province: string
  postal_code: string
  /** @pattern ^\d{3}-\d{3}-\d{4}$ */
  primary_phone_number: string
  /** Nullable */
  primary_phone_ext?: string
  /**
   * Nullable
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  secondary_phone_number?: string
  /** Nullable */
  secondary_phone_ext?: string
}

/**
 * Patch Participant Request
 * Request body to update a Participant model
 */
export interface PatchParticipantRequest {
  /** The account ID associated with this participant */
  account_id?: number
  /** The guardian's account ID, if applicable */
  guardian_account_id?: number
  /** @format email */
  email?: string
  last_name?: string
  first_name?: string
  /** Nullable */
  middle_name?: string
  /** Nullable */
  preferred_name?: string
  /** Nullable */
  language?: string
  /**
   * @format date
   * @example "2000-01-01"
   */
  date_of_birth?: string
  gender?: string
  /** The club ID associated with this participant */
  club_id?: number
  /** The league ID associated with this participant */
  league_id?: number
  /** Nullable */
  aboriginal_ancestry?: string
  /** Nullable */
  province_of_registration?: string
  address?: string
  /** Nullable */
  address2?: string
  city?: string
  province?: string
  postal_code?: string
  /** @pattern ^\d{3}-\d{3}-\d{4}$ */
  primary_phone_number?: string
  /** Nullable */
  primary_phone_ext?: string
  /**
   * Nullable
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  secondary_phone_number?: string
  /** Nullable */
  secondary_phone_ext?: string
}

/**
 * Account
 * Account Model
 */
export interface Account {
  /** Unique identifier for the Account */
  id: number
  /** First name of the participant */
  first_name: string
  /** Last name of the participant */
  last_name: string
  /**
   * Email address of the Account
   * @format email
   * @example "user@example.com"
   */
  email: string
  /** Email address of the account has been verified */
  verified: boolean
  /** participant owned by the account */
  participant: ParticipantSummary | null
  /** List of participants associated with the account */
  dependants: ParticipantSummary[]
  /** last saved cart */
  cart: CartValidationRequest | null
}

/**
 * Club
 * Represents the details of a Club
 */
export interface Club {
  /** Unique identifier of the Club */
  id: number
  /** Name of the Club */
  name: string
  /** Status indicating whether the Club is active (true) or inactive (false) */
  region: boolean
  /** Weight indicating the order of importance or hierarchy of the Club */
  weight: number
}

/**
 * Configuration Variable
 * A group of configuration variables
 */
export interface ConfigurationVariable {
  /** The name of the configuration variable */
  name: string
  /** The slug of the configuration variable */
  key: string
  /** The value of the configuration variable, which can be either a string or an array of strings */
  value: string | number | string[]
}

/**
 * CourseLocation
 * Represents the details of a Course Location
 */
export interface CourseLocation {
  /** Unique identifier of the Course Location */
  id: number
  /** Name of the location */
  name: string
  /** Primary address of the location */
  address: string
  /** Secondary address of the location */
  address2: string
  /** City where the location is situated */
  city: string
  /** Province where the location is situated */
  province: string
  /** Postal code of the location */
  postal_code: string
  /** Primary contact phone number for the location */
  primary_phone_number: string
  /** Weight indicating the order of importance or hierarchy of the location */
  weight: number
  /** Status indicating whether the location is active (true) or inactive (false) */
  status: boolean
}

/**
 * Course Registration
 * Detailed information about a course registration
 */
export type CourseRegistration = RegistrationResource

/**
 * Course
 * Represents the details of a Course
 */
export type Course = {
  /** Type of the Course */
  type?: string
  /** Description of the Course */
  description?: string
  /** Level of the Course */
  courseLevel?: string
  /** Location where the Course is held */
  courseLocation?: object
  /** Number of seats available in the Course */
  seats?: number
  /**
   * Registration end date for the Course
   * @format date
   */
  registration_end_date?: string
  /**
   * Start date of the Course
   * @format date
   */
  start_date?: string
  /**
   * End date of the Course
   * @format date
   */
  end_date?: string
  /** Status indicating whether the Course is active (true) or inactive (false) */
  status?: boolean
  /** List of questions associated with the Program */
  questions?: Question[]
} & PurchasableResource

/**
 * Document
 * Represents the details of a Signed Document
 */
export interface Document {
  /** Unique identifier for the Document */
  id: number
  /** The envelope group for the Document */
  envelope: string
  /** The URL of the resource */
  url: string
  /** The name of the document */
  document: string
  /** Full name of the participant */
  participant_name: string
  /** The unique identifier of the participant */
  participant: number
  /** The unique identifier of the product */
  product: number
  /** The class name of the product, printable version */
  product_type: string
  /** The name of the product */
  product_name: string
  /** The description of the product. */
  product_description: string
  /** The name of account holder who signed the document */
  signed_by: string | null
  /**
   * The creation timestamp of the object, formatted as YYYY-MM-DD HH:MM:SS
   * @format date-time
   */
  created: string
}

/**
 * Invoice
 * Invoice model
 */
export interface Invoice {
  /** Unique invoice number */
  invoice_number?: number
  /** paid, unpaid */
  status?: string
  /** total without tax */
  subtotal?: number
  /** amount to pay for taxes */
  taxes?: number
  /** Total */
  total?: number
  /**
   * The date of the invoice
   * @format date
   */
  date?: string
  /** List of product rows */
  item_rows?: InvoiceRow[]
  /** List of payments made on the invoice */
  payments?: Payment[]
}

/**
 * Cart Item
 * Model representing an item in the cart
 */
export interface CartItemResponse {
  /** ID of the product */
  product?: number
  /** Quantity of the product */
  quantity?: number
  /** Type of the product */
  product_type?: string
  /** ID of the payment option */
  payment_option?: number | null
  /** ID of the participant */
  participant?: number
  /** Answers to additional signup questions */
  answers?: QuestionAnswer[]
}

/**
 * Invoice Row
 * Invoice Row model
 */
export interface InvoiceRow {
  /** Model representing an item in the cart */
  cart_item?: CartItemResponse
  /** Description of the cart item */
  description?: string
  /** Invoice name of the participant */
  participant_name?: string
  /** Provincial price formatted to two decimal places */
  provincial_price?: number
  /** National price formatted to two decimal places */
  national_price?: number
  /** Total cost of the cart item formatted to two decimal places */
  cost?: number
  /** Tax applied on the cart item formatted to two decimal places */
  cost_tax?: number
  /** This record has been superseded by a credit */
  refundable?: boolean
  /** Credit - no payment option */
  credit?: boolean
}

/**
 * League Organization
 * Represents the details of a League
 */
export interface League {
  /** Unique identifier of the League */
  id: number
  /** Name of the League */
  name: string
  /** Status indicating whether the League is active (true) or inactive (false) */
  region: boolean
  /** Weight indicating the order of importance or hierarchy of the League */
  weight: number
}

/**
 * LookupGroup
 * A group of lookups
 */
export interface LookupGroup {
  /** The unique identifier for the lookup group */
  id?: number
  /** The name of the group in the default language */
  name?: string
  /** The name of the group in French */
  name_fr?: string
  /** An array of lookups within this group */
  lookups?: Lookup[]
}

/**
 * Lookup
 * A single lookup instance representing a configurable value within a group
 */
export interface Lookup {
  /** The value of the lookup (defaults to english name)) */
  value?: string
  /** The name of the lookup in the default language */
  name?: string
  /** The name of the lookup in French */
  name_fr?: string
  /** A weight for ordering the lookups in listings */
  weight?: number
  /** A brief description of the lookup */
  description?: string | null
}

/** Data related to the notification, structure may vary. Includes a mandatory 'message' property and optional 'url' and 'url_label' properties. */
export interface ParticipantNotificationData {
  /** A message describing the notification */
  message?: string
  /** Label for the URL */
  url_label?: string | null
  /**
   * URL associated with the notification
   * @format uri
   */
  url?: string | null
  [key: string]: any
}

/**
 * Notification
 * Notification model for user notifications
 */
export interface ParticipantNotification {
  /** Unique notification id */
  id?: number
  /** Participant Id */
  participant?: number
  /** name of the participant */
  participant_name?: string
  /** Data related to the notification, structure may vary. Includes a mandatory 'message' property and optional 'url' and 'url_label' properties. */
  data?: ParticipantNotificationData
  /** message type */
  type?: string
  /**
   * Timestamp when the notification was read
   * @format date-time
   */
  read_at?: string | null
  /**
   * Timestamp when the notification was created
   * @format date-time
   */
  created_at?: string
}

/**
 * Participant Lookup Result
 * Participant Lookup Result
 */
export interface ParticipantLookupResult {
  /** Unique participant id */
  id: number
  /** Unique participant number */
  participant_number: number
  /**
   * Masked email of the participant
   * @format email
   */
  email: string
  /** First name of the participant */
  first_name: string
  /** Last name of the participant */
  last_name: string
  /** The last season the participant was registered in */
  last_season_of_registration: string | null
  /**
   * The name of the participant's club
   * @example "2023-2024"
   */
  club_name: string | null
}

/**
 * Participant Profile
 * Detailed information about a participant's profile, including guardian details.
 */
export interface ParticipantProfile {
  /** First name of the first guardian */
  guardian1_first_name?: string
  /** Last name of the first guardian */
  guardian1_last_name?: string
  /**
   * Email address of the first guardian
   * @format email
   */
  guardian1_email?: string
  /**
   * Phone number of the first guardian
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  guardian1_phone?: string
  /** First name of the second guardian */
  guardian2_first_name?: string
  /** Last name of the second guardian */
  guardian2_last_name?: string
  /**
   * Email address of the second guardian
   * @format email
   */
  guardian2_email?: string
  /**
   * Phone number of the second guardian
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  guardian2_phone?: string
}

/**
 * Participant Registration
 * Detailed information about a participant registration, including status flags, registration type details, and related registration option attributes.
 */
export type ParticipantRegistration = RegistrationResource & {
  /**
   * The expiry date of the participant registration.
   * @format date
   */
  expiry_date?: string | null
  /** Name of the registration type in the default language. */
  registration_type_name?: string
  /** Voting code associated with the registration option. */
  voting_code?: string
}

/**
 * Participant
 * Participant model
 */
export interface Participant {
  /** Unique participant id */
  id?: number
  /** Unique participant number */
  participant_number?: number
  /**
   * Email of the participant
   * @format email
   */
  email?: string
  /** Last name of the participant */
  last_name?: string
  /** First name of the participant */
  first_name?: string
  /** Middle name of the participant */
  middle_name?: string | null
  /** Preferred name of the participant */
  preferred_name?: string | null
  /** Preferred language of the participant */
  language?: string | null
  /**
   * Date of birth of the participant
   * @format date
   */
  date_of_birth?: string
  /** Gender of the participant */
  gender?: string | null
  /** Primary address of the participant */
  address?: string
  /** Secondary address of the participant */
  address2?: string | null
  /** City of the participant */
  city?: string
  /** Province of the participant */
  province?: string
  /** Postal code of the participant */
  postal_code?: string
  /**
   * Primary phone number of the participant
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  primary_phone_number?: string
  /** Extension for the primary phone number */
  primary_phone_ext?: string | null
  /**
   * Secondary phone number of the participant
   * @pattern ^\d{3}-\d{3}-\d{4}$
   */
  secondary_phone_number?: string | null
  /** Extension for the secondary phone number */
  secondary_phone_ext?: string | null
  /** Id of the participants profile */
  profile?: number | null
  /** Name of the owner internal organization */
  organization?: string | null
  /** Name of the owner club */
  club?: string | null
  /** Name of the owner league */
  league?: string | null
  /** List of accounts associated with the participant, including the nature of their relationship */
  accounts?: AccountRelationship[]
  /** List of active or upcoming activities */
  activity_summary?: {
    /** List of participant Registration associated with the participant */
    participant_registrations?: ParticipantRegistration[]
    /** List of participant programs */
    program_registrations?: Program[]
    /** List of participant courses */
    course_registrations?: Course[]
  }
  /** Flag indicating if the participant is a player. */
  player_flag?: boolean
  /** Flag indicating if the participant is a coach. */
  coach_flag?: boolean
  /** Flag indicating if the participant is a official. */
  official_flag?: boolean
  /** list of unread notifications */
  new_notifications?: ParticipantNotification[]
  /** 5 most recent notifications that have been read */
  old_notifications?: ParticipantNotification[]
  /** Count of pending actions */
  pending?: {
    /** The number of documents the participant needs to sign */
    documents?: number
    /** The number of new notifications for the participant */
    notifications?: number
  }
}

/**
 * Account
 * Account Model
 */
export interface AccountRelationship {
  /** Unique identifier for the Account */
  id?: number
  /**
   * Email address of the Account
   * @format email
   * @example "user@example.com"
   */
  email?: string
  /** The nature of the relationship between the Participant and the Account */
  relationship?: string
}

/**
 * Participant Summary
 * Participant Summary
 */
export interface ParticipantSummary {
  /** Unique participant id */
  id?: number
  /** Unique participant number */
  participant_number?: number
  /**
   * Email of the participant
   * @format email
   */
  email?: string
  /** Last name of the participant */
  last_name?: string
  /** First name of the participant */
  first_name?: string
  /** Preferred name of the participant */
  preferred_name?: string | null
  /**
   * Date of birth of the participant
   * @format date
   */
  date_of_birth?: string
  /** Gender of the participant */
  gender?: string | null
  /** Province of the participant */
  province?: string
  /** Primary phone number of the participant */
  primary_phone_number?: string
  /** list of unread notifications */
  new_notifications?: ParticipantNotification[]
  /** 5 most recent notifications that have been read */
  old_notifications?: ParticipantNotification[]
  /** Flag indicating if the participant is a player. */
  player_flag?: boolean
  /** Flag indicating if the participant is a coach. */
  coach_flag?: boolean
  /** Flag indicating if the participant is a official. */
  official_flag?: boolean
  /** Count of pending actions */
  pending?: {
    /** The number of documents the participant needs to sign */
    documents?: number
    /** The number of new notifications for the participant */
    notifications?: number
  }
}

/**
 * Payment Option
 * Payment Option model
 */
export interface PaymentOption {
  /** Unique payment option id */
  id?: number
  /** PSO Price */
  provincial_price?: number
  /** NSO price */
  national_price?: number
  /** tax rate */
  tax_rate?: number
  /** province */
  province?: string
  /** payment method */
  payment_method?: string | null
  /** name/description of product */
  description?: string | null
}

/**
 * Payment
 * Payment model
 */
export interface Payment {
  /** transaction number */
  auth?: string
  /** paid, unpaid */
  status?: string
  /** amount paid */
  amount?: number
  /** Credit, Debit, ect */
  method?: string
  /**
   * The date of the transaction
   * @format date-time
   */
  date?: string
}

/**
 * Program Registration
 * Detailed information about a program registration
 */
export type ProgramRegistration = RegistrationResource

/**
 * Program
 * Represents the details of a Program
 */
export type Program = {
  /** Classification of the Program */
  classification?: string
  /** Zone of the Program */
  zone?: string
  /** Level of the Program */
  programLevel?: string
  /** Season when the Program is active */
  season?: string
  /** Year of the Program */
  year?: number
  /** Venue of the Program */
  venue?: string
  /** Is the program limited to a specific gender/sex */
  gender?: string
  /** Is this program a trial */
  trial?: string
  /** Description of the Program */
  description?: string
  /**
   * Date when the Program is published
   * @format date
   */
  registration_start_date?: string
  /**
   * Date when the Program is unpublished
   * @format date
   */
  registration_end_date?: string
  /** Status indicating whether the Program is active (true) or inactive (false) */
  status?: boolean
  /** List of questions associated with the Program */
  questions?: Question[]
} & PurchasableResource

/**
 * Purchasable Resource
 * Represents all shared fields between purchasable models
 */
export interface PurchasableResource {
  /** Unique identifier */
  id?: number
  /** Name of the Model */
  name?: string
  /** Weight indicating the order of importance or hierarchy */
  weight?: number
  /** Max cost of the payment options */
  cost?: number
  /** List of payment options associated with the option */
  payment_options?: PaymentOption[]
}

/**
 * Question
 * Represents the details of a Question
 */
export interface Question {
  /** Unique id */
  id: number
  /** The question */
  question: string
  /** The widget type for the question format */
  classification: string
  /** List of available answer options to the question */
  options: string[] | null
  /** Display Weight */
  weight?: number
  /** Required Flag */
  required?: boolean
}

/**
 * Registration Option
 * Represents the details of a registration option, including bilingual names and descriptions.
 */
export type RegistrationOption = {
  /** Name of the registration type in English */
  registration_type_name?: string
  /** Description of the registration option in English */
  description?: string
  /** The year the registration applied to */
  year?: number
  /** Code related to voting rights or procedures associated with the registration option */
  voting_code?: string
  /** Code related to role of the registration option */
  role_code?: string
  /** The year the participant must be born after (inclusive) */
  birth_year_low?: number
  /** The year the participant must be born before (inclusive) */
  birth_year_high?: number
  /**
   * The expiry date of the registration type, if applicable
   * @format date
   */
  expiry_date?: string
} & PurchasableResource

/**
 * Registration
 * Detailed information about a registration.
 */
export interface RegistrationResource {
  /** Unique registration id */
  registration?: number
  /** Class name of the registration */
  registration_type?: string
  /** Name of the registration option in the default language. */
  name?: string
  /** The year the registration applied to */
  year?: number
  /** The program year/season */
  program_year?: string
  /** Description of the registration option in the default language. */
  description?: string
}

/**
 * Zone
 * A single lookup instance representing a configurable value within a group
 */
export interface Zone {
  /** The parent zone */
  parent_zone_id?: string
  /** The name of zone */
  name?: string
  /** The type of zone (region or zone or neither) */
  type?: string
  /** A weight for ordering the lookups in listings */
  weight?: number
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key)
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
        },
        signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title FHBC
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Login by email and password, returns access and refresh tokens.
     *
     * @tags Authentication
     * @name LoginAccount
     * @summary Logs in a account
     * @request POST:/api/auth/login
     */
    loginAccount: (
      data: {
        /** Account Login Object */
        data: LoginAccount
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Authorization Structure */
          authorization?: AuthStruct
          data?: {
            /** Account Model */
            account?: Account
          }
        },
        | GenericErrorModel
        | AccountVerifiedError
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Refreshes the authentication token using a refresh token from an http-only cookie, returns new access and refresh tokens.
     *
     * @tags Authentication
     * @name RefreshToken
     * @summary Refreshes the account's authentication token
     * @request POST:/api/auth/refresh
     */
    refreshToken: (params: RequestParams = {}) =>
      this.request<
        {
          /** Authorization Structure */
          authorization?: AuthStruct
          data?: {
            /** Account Model */
            account?: Account
          }
        },
        | {
            /** @example "Unauthorized" */
            error?: string
          }
        | AccountVerifiedError
      >({
        path: `/api/auth/refresh`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * @description Verifies the account's email address using an ID and a hash of the email. This is typically done through a verification link sent to the user's email address.
     *
     * @tags Authentication
     * @name VerifyEmail
     * @summary Verify the account's email address
     * @request GET:/api/email/verify/{id}/{hash}
     */
    verifyEmail: (
      id: number,
      hash: string,
      query?: {
        /** The expiry time of the verification link */
        expires?: number
        /** The cryptographic signature to validate the request */
        signature?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "Account Verified" */
          message?: string
        },
        {
          /** List of form field errors */
          message?: FormItemError[]
        }
      >({
        path: `/api/email/verify/${id}/${hash}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Resend the verification email based on the provided email address.
     *
     * @tags Authentication
     * @name ResendVerificationEmail
     * @summary Resend verification email
     * @request POST:/api/email/verify/resend
     */
    resendVerificationEmail: (
      data: {
        /**
         * @format email
         * @example "account@example.com"
         */
        email?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "Verification email resent." */
          message?: string
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/email/verify/resend`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Sends a password reset link to the given email if it exists in the system.
     *
     * @tags Authentication
     * @name RequestPasswordReset
     * @summary Request a password reset link
     * @request POST:/api/forgot-password
     */
    requestPasswordReset: (
      data: {
        /** Initial Request to reset account password */
        data: ForgotPasswordRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "Password Reset Email Sent" */
          message?: string
        },
        | {
            /** @example "Error" */
            error?: string
          }
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/forgot-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Updates the account's password using a password reset token.
     *
     * @tags Authentication
     * @name ResetPassword
     * @summary Update the account's password
     * @request POST:/api/reset-password
     */
    resetPassword: (
      data: {
        /** Initial Request to reset account password */
        data: ResetPasswordRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "Password updated successfully" */
          message?: string
        },
        | {
            /** @example "Error" */
            error?: string
          }
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single Account
     *
     * @tags Accounts
     * @name GetAccountById
     * @summary Get Account by ID
     * @request GET:/api/accounts/{id}
     * @secure
     */
    getAccountById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Account Model */
          data?: Account
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Creates a new Account and returns the account data
     *
     * @tags Accounts
     * @name CreateAccount
     * @summary Create a new Account
     * @request POST:/api/accounts
     * @secure
     */
    createAccount: (
      data: {
        /** Request body to store a Account model */
        data: StoreAccountRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Account Model */
          data?: Account
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/accounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Updates an existing account with the provided information. Requires authentication and the account's ID.
     *
     * @tags Accounts
     * @name UpdateAccount
     * @summary Update an existing account
     * @request PATCH:/api/accounts/{accountId}
     * @secure
     */
    updateAccount: (
      accountId: number,
      data: {
        /** Request body to store a Account model */
        data: StoreAccountRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Account Model */
          data?: Account
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Attaches a participant to a specific account by IDs
     *
     * @tags Accounts
     * @name AttachParticipantToAccount
     * @summary Attach a participant to an Account
     * @request POST:/api/accounts/{accountId}/participants/{participantId}
     * @secure
     */
    attachParticipantToAccount: (
      accountId: number,
      participantId: number,
      data: {
        data: {
          /**
           * The verification code sent to the participant
           * @example "820734"
           */
          verification_code: string
          /** The relationship of the participant to the account */
          relationship: 'Owner' | 'Guardian'
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Account Model */
          data?: Account
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/accounts/${accountId}/participants/${participantId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description requests to attach a participant to an account
     *
     * @tags Accounts
     * @name RequestParticipantToAccount
     * @summary Request to attach a participant to an Account
     * @request POST:/api/accounts/{accountId}/participants/{participantId}/request
     * @secure
     */
    requestParticipantToAccount: (
      accountId: number,
      participantId: number,
      data: {
        data: {
          /** The relationship of the participant to the account */
          relationship: 'Owner' | 'Guardian'
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "Email Verification Email Sent" */
          message?: string
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/accounts/${accountId}/participants/${participantId}/request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all invoices for an account
     *
     * @tags Accounts
     * @name GetInvoices
     * @summary Get Account Invoices
     * @request GET:/api/accounts/{accountID}/invoices
     * @secure
     */
    getInvoices: (
      accountId: number,
      query?: {
        /**
         * Page number
         * @default 1
         */
        page?: number
        /**
         * Number of items per page
         * @default 25
         */
        perPage?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** List of product rows */
            item_rows?: Invoice[]
          }
          /** @example "124" */
          total?: number
          /** @example "25" */
          per_page?: number
          /** @example "1" */
          current_page?: number
          /** @example "5" */
          last_page?: number
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/invoices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single invoice for an account
     *
     * @tags Accounts
     * @name GetSingleInvoice
     * @summary Get Single Invoice for Account
     * @request GET:/api/accounts/{accountID}/invoices/{invoiceID}
     * @secure
     */
    getSingleInvoice: (accountId: number, invoiceId: number, params: RequestParams = {}) =>
      this.request<Invoice, GenericErrorModel>({
        path: `/api/accounts/${accountId}/invoices/${invoiceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Downloads a PDF of the specified invoice
     *
     * @tags Accounts
     * @name DownloadInvoicePdf
     * @summary Download Invoice PDF
     * @request GET:/api/accounts/{accountID}/invoices/{invoiceID}/download
     * @secure
     */
    downloadInvoicePdf: (accountId: number, invoiceId: number, params: RequestParams = {}) =>
      this.request<File, GenericErrorModel>({
        path: `/api/accounts/${accountId}/invoices/${invoiceId}/download`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * @description Returns all documents for an account
     *
     * @tags Accounts
     * @name GetDocuments
     * @summary Get Account Documents
     * @request GET:/api/accounts/{accountID}/documents
     * @secure
     */
    getDocuments: (accountId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Document[]
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single document
     *
     * @tags Accounts
     * @name GetDocument
     * @summary Get Document by Id
     * @request GET:/api/accounts/{accountId}/documents/{documentId}
     * @secure
     */
    getDocument: (accountId: number, documentId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Document[]
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/documents/${documentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Redirects to the embedded signing window from docusign
     *
     * @tags Accounts
     * @name GetDocumentUrl
     * @summary Open Docusign url for document
     * @request GET:/api/accounts/{accountID}/documents/{documentId}/url
     * @secure
     */
    getDocumentUrl: (accountId: number, documentId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            /** url to sign document */
            url?: string
          }
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/documents/${documentId}/url`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Triggers a check to see if the Docusign document has been signed
     *
     * @tags Accounts
     * @name SetDocumentSigned
     * @summary Set Docusign document to signed
     * @request PATCH:/api/accounts/{accountID}/documents/{documentId}/signed
     * @secure
     */
    setDocumentSigned: (accountId: number, documentId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Document[]
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/documents/${documentId}/signed`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all Notifications for an account's participants
     *
     * @tags Accounts
     * @name GetNotifications
     * @summary Get Account Notifications
     * @request GET:/api/accounts/{accountID}/notifications
     * @secure
     */
    getNotifications: (accountId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            new_notifications?: ParticipantNotification[]
            old_notifications?: ParticipantNotification[]
          }
        },
        GenericErrorModel
      >({
        path: `/api/accounts/${accountId}/notifications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Get status of api
     *
     * @tags API
     * @name GetStatus
     * @summary Get status of api
     * @request GET:/api/status/
     */
    getStatus: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            /** @example "on" */
            value?: string
          }
        },
        GenericErrorModel
      >({
        path: `/api/status/`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Purchase all items in the cart
     *
     * @tags Cart
     * @name PurchaseCart
     * @summary Purchase all items in the cart
     * @request POST:/api/cart/purchase
     * @secure
     */
    purchaseCart: (
      data: {
        data: CartRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: {
            /** status message */
            message: string
            /** are there documents related with this purchase */
            documents: boolean
            /** Invoice model */
            invoice: Invoice
          }
        },
        | GenericErrorModel
        | {
            /** List of cart item row errors */
            message?: CartItemError[]
          }
      >({
        path: `/api/cart/purchase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Validate all items in the cart
     *
     * @tags Cart
     * @name ValidateCart
     * @summary Validate all items in the cart
     * @request POST:/api/cart/validate
     * @secure
     */
    validateCart: (
      data: {
        data: CartValidationRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** The token for cart validation */
            cart_validation_token?: string
            /** Invoice model */
            invoice?: Invoice
          }
        },
        | GenericErrorModel
        | {
            /** List of cart item row errors */
            message?: CartItemError[]
          }
      >({
        path: `/api/cart/validate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Validate a singe item to be added to the cart
     *
     * @tags Cart
     * @name ValidateProduct
     * @summary Validate a singe item to be added to the cart
     * @request POST:/api/cart/validateProduct
     * @secure
     */
    validateProduct: (data: CartItemRequest, params: RequestParams = {}) =>
      this.request<
        {
          /** Invoice Row model */
          data?: InvoiceRow
        },
        | GenericErrorModel
        | {
            /** List of cart item row errors */
            message?: CartItemError[]
          }
      >({
        path: `/api/cart/validateProduct`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all lookup group options available
     *
     * @tags Configuration
     * @name GetLookups
     * @summary Get all Configuration Lookup Groups
     * @request GET:/api/config/lookups
     */
    getLookups: (params: RequestParams = {}) =>
      this.request<
        {
          /** List of Variables */
          data?: LookupGroup[]
        },
        any
      >({
        path: `/api/config/lookups`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all variable options available
     *
     * @tags Configuration
     * @name GetConfigurationVariables
     * @summary Get all Configuration variables
     * @request GET:/api/config/variables
     */
    getConfigurationVariables: (params: RequestParams = {}) =>
      this.request<
        {
          /** List of Variables */
          data?: ConfigurationVariable[]
        },
        any
      >({
        path: `/api/config/variables`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all lookup group options available
     *
     * @tags Configuration
     * @name GetZones
     * @summary Get all Configuration Zones
     * @request GET:/api/config/zones
     */
    getZones: (params: RequestParams = {}) =>
      this.request<
        {
          /** List of zone options */
          data?: Zone[]
        },
        any
      >({
        path: `/api/config/zones`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all Courses available
     *
     * @tags Courses
     * @name GetAllCourses
     * @summary Get all Courses
     * @request GET:/api/courses
     * @secure
     */
    getAllCourses: (
      query?: {
        /**
         * Filter course options by the date (formatted as yyyy-mm-dd)
         * @format date
         */
        start_date?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** List of course options */
          data?: Course[]
        },
        GenericErrorModel
      >({
        path: `/api/courses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single courses
     *
     * @tags Courses
     * @name GetCourseById
     * @summary Get courses by ID
     * @request GET:/api/courses/{id}
     * @secure
     */
    getCourseById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Represents the details of a Course */
          data?: Course
        },
        GenericErrorModel
      >({
        path: `/api/courses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all clubs available
     *
     * @tags Organizations
     * @name GetAllClubs
     * @summary Get all Clubs
     * @request GET:/api/clubs
     */
    getAllClubs: (params: RequestParams = {}) =>
      this.request<
        {
          /** List of clubs */
          data?: Club[]
        },
        GenericErrorModel
      >({
        path: `/api/clubs`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all clubs available
     *
     * @tags Organizations
     * @name GetAllLeagues
     * @summary Get all Leagues
     * @request GET:/api/leagues
     */
    getAllLeagues: (params: RequestParams = {}) =>
      this.request<
        {
          /** List of Leagues */
          data?: League[]
        },
        GenericErrorModel
      >({
        path: `/api/leagues`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single Club
     *
     * @tags Organizations
     * @name GetClubById
     * @summary Get Club by ID
     * @request GET:/api/clubs/{id}
     * @secure
     */
    getClubById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Represents the details of a Club */
          data?: Club
        },
        GenericErrorModel
      >({
        path: `/api/clubs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single League
     *
     * @tags Organizations
     * @name GetLeagueById
     * @summary Get League by ID
     * @request GET:/api/leagues/{id}
     * @secure
     */
    getLeagueById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Represents the details of a League */
          data?: League
        },
        GenericErrorModel
      >({
        path: `/api/leagues/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single Participant
     *
     * @tags Participants
     * @name GetParticipantById
     * @summary Get Participant by ID
     * @request GET:/api/participants/{id}
     * @secure
     */
    getParticipantById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Participant model */
          data?: Participant
        },
        GenericErrorModel
      >({
        path: `/api/participants/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Marks a specified notification as read for a given participant and returns the updated participant information.
     *
     * @tags Notifications
     * @name MarkNotificationAsRead
     * @summary Mark a Notification as Read
     * @request POST:/api/participants/{id}/notifications/{notificationId}
     * @secure
     */
    markNotificationAsRead: (id: number, notificationId: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Participant model */
          data?: Participant
        },
        GenericErrorModel
      >({
        path: `/api/participants/${id}/notifications/${notificationId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Creates a new participant with the provided information. Requires authentication.
     *
     * @tags Participants
     * @name CreateParticipant
     * @summary Create a new participant
     * @request POST:/api/participants
     * @secure
     */
    createParticipant: (
      data: {
        /** Request body to store a Participant model */
        data: StoreParticipantRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Participant model */
          data?: Participant
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/participants`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Updates an existing participant with the provided information. Requires authentication and the participant's ID.
     *
     * @tags Participants
     * @name UpdateParticipant
     * @summary Update an existing participant
     * @request PATCH:/api/participants/{participantId}
     * @secure
     */
    updateParticipant: (
      participantId: number,
      data: {
        /** Request body to update a Participant model */
        data: PatchParticipantRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Participant model */
          data?: Participant
        },
        GenericErrorModel
      >({
        path: `/api/participants/${participantId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single Participant Profile
     *
     * @tags Participants
     * @name GetParticipantProfile
     * @summary Get Participant Profile by ID
     * @request GET:/api/participants/{id}/profile
     * @secure
     */
    getParticipantProfile: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Detailed information about a participant's profile, including guardian details. */
          data?: ParticipantProfile
        },
        GenericErrorModel
      >({
        path: `/api/participants/${id}/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Creates a new participant profile if one does not exist for the given participant, or updates the existing profile if one exists.
     *
     * @tags Participants
     * @name UpsertParticipantProfile
     * @summary Create or update a participant's profile
     * @request POST:/api/participants/{id}/profile
     * @secure
     */
    upsertParticipantProfile: (
      id: number,
      data: {
        /** Request body to store a Participant model */
        data: StoreParticipantProfileRequest
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** Detailed information about a participant's profile, including guardian details. */
          data?: ParticipantProfile
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/participants/${id}/profile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Performs a search for participants based on the specified search criteria. This can return multiple participants if more than one match is found.
     *
     * @tags Participants
     * @name ParticipantLookup
     * @summary Lookup participants based on provided criteria
     * @request POST:/api/participants/lookup
     * @secure
     */
    participantLookup: (
      data: {
        /** Participant Lookup Result */
        data: ParticipantLookupResult
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** List of participants */
          data?: Participant[]
        },
        | GenericErrorModel
        | {
            /** List of form field errors */
            message?: FormItemError[]
          }
      >({
        path: `/api/participants/lookup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all program the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantPrograms
     * @summary Get Participant Programs by participant ID
     * @request GET:/api/participants/{participantId}/programs
     * @secure
     */
    getParticipantPrograms: (
      participantId: number,
      query?: {
        /**
         * Page number
         * @default 1
         */
        page?: number
        /**
         * Number of items per page
         * @default 25
         */
        perPage?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** List of programs */
            programs?: Program[]
            /** List of program registrations */
            program_registrations?: ProgramRegistration[]
          }
          /** @example "124" */
          total?: number
          /** @example "25" */
          per_page?: number
          /** @example "1" */
          current_page?: number
          /** @example "5" */
          last_page?: number
        },
        GenericErrorModel
      >({
        path: `/api/participants/${participantId}/programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all program the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantProgram
     * @summary Get Participant Programs by participant ID
     * @request GET:/api/participants/{participantId}/programs/{programId}
     * @secure
     */
    getParticipantProgram: (participantId: number, programId: number, params: RequestParams = {}) =>
      this.request<void, GenericErrorModel>({
        path: `/api/participants/${participantId}/programs/${programId}`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * @description Returns all registration the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantParticipantRegistrations
     * @summary Get Participant Registrations by participant ID
     * @request GET:/api/participants/{participantId}/registrationOptions
     * @secure
     */
    getParticipantParticipantRegistrations: (
      participantId: number,
      query?: {
        /**
         * Page number
         * @default 1
         */
        page?: number
        /**
         * Number of items per page
         * @default 25
         */
        perPage?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** List of participant Registrations */
            registration_options?: RegistrationOption[]
            /** List of participant registrations */
            participant_registrations?: ParticipantRegistration[]
          }
          /** @example "124" */
          total?: number
          /** @example "25" */
          per_page?: number
          /** @example "1" */
          current_page?: number
          /** @example "5" */
          last_page?: number
        },
        GenericErrorModel
      >({
        path: `/api/participants/${participantId}/registrationOptions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a Registration Option the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantRegistrationOption
     * @summary Get Participant Registration Option by participant ID
     * @request GET:/api/participants/{participantId}/registrationOptions/{registrationOptionId}
     * @secure
     */
    getParticipantRegistrationOption: (
      participantId: number,
      registrationOptionId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, GenericErrorModel>({
        path: `/api/participants/${participantId}/registrationOptions/${registrationOptionId}`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * @description Returns all course the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantCourses
     * @summary Get Participant Courses by participant ID
     * @request GET:/api/participants/{participantId}/courses
     * @secure
     */
    getParticipantCourses: (
      participantId: number,
      query?: {
        /**
         * Page number
         * @default 1
         */
        page?: number
        /**
         * Number of items per page
         * @default 25
         */
        perPage?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** List of courses */
            courses?: Course[]
            /** List of course registrations */
            course_registrations?: CourseRegistration[]
          }
          /** @example "124" */
          total?: number
          /** @example "25" */
          per_page?: number
          /** @example "1" */
          current_page?: number
          /** @example "5" */
          last_page?: number
        },
        GenericErrorModel
      >({
        path: `/api/participants/${participantId}/courses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all course the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantCourse
     * @summary Get Participant Courses by participant ID
     * @request GET:/api/participants/{participantId}/courses/{courseId}
     * @secure
     */
    getParticipantCourse: (participantId: number, courseId: number, params: RequestParams = {}) =>
      this.request<void, GenericErrorModel>({
        path: `/api/participants/${participantId}/courses/${courseId}`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * @description Returns all Course, Registrations, Programs the participant has registered in
     *
     * @tags Participants
     * @name GetParticipantHistory
     * @summary Get Participant Course, Registrations, Programs by participant ID
     * @request GET:/api/participants/{participantId}/history
     * @secure
     */
    getParticipantHistory: (
      participantId: number,
      query?: {
        /** Year to filter by */
        year?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            /** List of course registrations */
            course_registrations?: CourseRegistration[]
            /** List of participant Registrations */
            participant_registrations?: ParticipantRegistration[]
            /** List of program registrations */
            program_registrations?: ProgramRegistration[]
          }
          /** A list of years associated with the participant's history. */
          historical_years?: number[]
          /** @example "2025" */
          queried_year?: number
          /** @example "2024-2025" */
          queried_season?: string
        },
        GenericErrorModel
      >({
        path: `/api/participants/${participantId}/history`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all programs available
     *
     * @tags Programs
     * @name GetAllPrograms
     * @summary Get all Programs
     * @request GET:/api/programs
     * @secure
     */
    getAllPrograms: (
      query?: {
        /** Filter programs by the year */
        year?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** List of programs */
          data?: Program[]
        },
        GenericErrorModel
      >({
        path: `/api/programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single program
     *
     * @tags Programs
     * @name GetProgramById
     * @summary Get Programs by ID
     * @request GET:/api/programs/{id}
     * @secure
     */
    getProgramById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Represents the details of a Program */
          data?: Program
        },
        GenericErrorModel
      >({
        path: `/api/programs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns all registration options available
     *
     * @tags RegistrationOptions
     * @name GetAllRegistrationOptions
     * @summary Get all Registration Options
     * @request GET:/api/registrationOptions
     * @secure
     */
    getAllRegistrationOptions: (
      query?: {
        /** Filter registration options by the year */
        year?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** List of registration options */
          data?: RegistrationOption[]
        },
        GenericErrorModel
      >({
        path: `/api/registrationOptions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns a single registrationOptions
     *
     * @tags RegistrationOptions
     * @name GetRegistrationOptionById
     * @summary Get registrationOptions by ID
     * @request GET:/api/registrationOptions/{id}
     * @secure
     */
    getRegistrationOptionById: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          /** Represents the details of a registration option, including bilingual names and descriptions. */
          data?: RegistrationOption
        },
        GenericErrorModel
      >({
        path: `/api/registrationOptions/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * @description Returns API Key for checkout
     *
     * @tags Cart
     * @name GetApiKey
     * @summary Get API Key for checkout
     * @request GET:/api/cart/apiKey
     * @secure
     */
    getApiKey: (
      query: {
        /**
         * The total amount of the transaction
         * @format float
         * @example 100
         */
        amount: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** API Key for  SportsPay checkout */
          data?: SportsPayApiKey
        },
        GenericErrorModel
      >({
        path: `/api/cart/apiKey`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  }
}
