/**
 *
 * generate api.ts by doing : pnpm run generate:api ...
 *
 * consider also using
 *
 */
import { CONFIG } from '@/config'
import type { Account, AuthStruct, GenericErrorModel, HttpResponse } from '@/services/api'
import { Api, ContentType } from '@/services/api'

import { useUserStore } from '@/stores/user'

export const limit = 10
let refreshingToken = false
let tokenRefreshPromise: Promise<any> | null = null

export const api = new Api({
  baseUrl: process.env.NODE_ENV === 'production' ? `${CONFIG.API_BASE_URL}` : '',
  // eslint-disable-next-line
  securityWorker: (_) => {
    const authStore = useUserStore()

    const token = authStore.getAccessToken()
    if (token != undefined) {
      return { headers: { Authorization: `Bearer ${token}` } }
    }
  },
  baseApiParams: {
    headers: {
      'Content-Type': ContentType.Json
    },
    format: 'json'
  },
  customFetch: new Proxy(fetch, {
    async apply(target, _, argArray) {
      const [url, originalOptions] = argArray
      const options = { ...originalOptions, credentials: 'include' }

      // should not check for a token on these paths..
      const checkAuthorizationToken = options.headers['Authorization'] ?? false

      // Catch any request made while refreshing token
      const waitForTokenRefresh = () => {
        if (!tokenRefreshPromise) {
          tokenRefreshPromise = new Promise((resolve, reject) => {
            fetchRefreshedAuth()
              .then(() => {
                refreshingToken = false
                resolve(true)
                tokenRefreshPromise = null // Reset the promise after successful refresh
              })
              .catch((error) => {
                console.error('Error refreshing token:', error)
                reject(error)
                tokenRefreshPromise = null // Reset on error as well
              })
          })
        }
        return tokenRefreshPromise
      }

      const updateHeadersWithToken = () => {
        const { getAccessToken } = useUserStore()
        const newToken = getAccessToken()
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${newToken}`
        }
      }

      if (checkAuthorizationToken) {
        if (refreshingToken) {
          await waitForTokenRefresh()
          updateHeadersWithToken()
        } else {
          const { triggerRefreshCalc } = useUserStore()
          const refreshTheToken = triggerRefreshCalc()
          if (refreshTheToken) {
            // wait for the new refresh token
            refreshingToken = true // Mark as refreshing
            await waitForTokenRefresh() // Perform the refresh
            updateHeadersWithToken()
          }
        }
      }

      const isLoginApiCall = url.match('auth/login') ?? false
      try {
        const response = await Reflect.apply(target, window, [url, options])
        return response
      } catch (error) {
        // don't want to do a redirect if it's already a login
        if (!isLoginApiCall) {
          console.error(['Reflect.apply response errored out -- ', error])
        }
      }
    }
  })
})

export async function fetchRefreshedAuth() {
  const { updateUser, updateAuth, logout } = useUserStore()

  try {
    const response = await api.api.refreshToken()
    const result = response.data as {
      authorization?: AuthStruct
      data?: {
        account?: Account
      }
    }

    if (result.data && result.authorization) {
      updateUser(result.data.account)
      api.setSecurityData(result.authorization.access_token)
      updateAuth(result.authorization)
      console.log(['Updated userData, accessToken, authorization'])
    }
  } catch (error) {
    logout()
    return Promise.reject(error)
  }
}

export function isFetchError<E = GenericErrorModel>(e: unknown): e is HttpResponse<unknown, E> {
  console.log(['isError', e])
  return e instanceof Object && 'error' in e
}
