import type { Participant, ParticipantSummary } from '@/services/api'
import { api, isFetchError } from '@/services'
import { useGlobalStore } from '@/stores/global'

export function getParticipantFullname(
  participant: Participant | ParticipantSummary | undefined
): string {
  if (!participant) {
    return 'n/a'
  }

  const ary = []
  if (participant?.preferred_name ?? false) {
    ary.push(participant?.preferred_name)
  } else {
    ary.push(participant?.first_name)
  }

  ary.push(participant?.last_name)

  const num = participant.participant_number ?? 0

  return ary.length > 0 ? ary.join(' ') : num.toString()
}

export async function markNotificationRead(participantId: number, notificationid: number) {
  const globalStore = useGlobalStore()

  try {
    globalStore.incrementLoading()
    await api.api.markNotificationAsRead(participantId, notificationid)

    // await routerPush('home')
  } catch (error) {
    if (isFetchError(error)) {
      // const msg = error.error?.message ?? false
    }
    console.error(error)
  } finally {
    globalStore.decrementLoading()
  }
}
