import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type {
  Billing,
  CartItem,
  CartItemError,
  CartValidationRequest,
  Invoice,
  InvoiceRow,
  PaymentOption
} from '@/services/api'
import { api, isFetchError } from '@/services'

// import _, { parseInt } from 'lodash'
import { processApiAndCartErrors } from '@/utils/helpers-api'

// clearing pinia stores:
// https://github.com/vuejs/pinia/discussions/911

export const PRODUCT_TYPE_REGISTRATIONOPTION = 'RegistrationOption'
export const PRODUCT_TYPE_COURSE = 'Course'
export const PRODUCT_TYPE_PROGRAM = 'Program'

export interface FormAnswerError {
  question_id: number
  answer: string | string[] | undefined
  errorMessage: string[]
}

export interface CartTotals {
  subtotal: number
  taxes: number
  total: number
}

export interface PaymentResult {
  status: boolean
  documents: boolean
  message: string
  transactionCode?: string
}

export interface AttributeError {
  attribute: string
  message: string
}

export interface CartError {
  item_idx: number
  errors: AttributeError[]
}

export interface ShoppingCart {
  cart_validation_token?: string
  invoice?: Invoice
}

export const useCartStore = defineStore(
  'cart',
  () => {
    const shoppingCart = ref<ShoppingCart>()
    const invoice = ref<ShoppingCart>()
    const invoiceErrors = ref<CartItemError[]>([])

    const errors = ref<CartItemError[]>([])
    const processingCartData = ref(false)
    const intializedCart = ref(false)

    /************** CHECKOUT INVOICE RELATED *****************/

    function getInvoiceTotals() {
      return {
        subtotal: invoice.value?.invoice?.subtotal ?? 0,
        taxes: invoice.value?.invoice?.taxes ?? 0,
        total: invoice.value?.invoice?.total ?? 0
      }
    }

    // get a list of item rows from the INVOICE for display before checking out
    const getInvoiceItems = computed(() => {
      return invoice.value?.invoice?.item_rows ?? []
    })

    const getInvoiceTotal = computed(() => {
      const totals = getInvoiceTotals()
      return totals.total
    })

    /************** CART RELATED *****************/

    function resetCart() {
      intializedCart.value = true
      shoppingCart.value = {}
      errors.value = []
    }

    function getCartTotals() {
      return {
        subtotal: shoppingCart.value?.invoice?.subtotal ?? 0,
        taxes: shoppingCart.value?.invoice?.taxes ?? 0,
        total: shoppingCart.value?.invoice?.total ?? 0
      }
    }

    // get a list of items rows from the CART for display of the current cart
    const getCartItems = computed(() => {
      return shoppingCart.value?.invoice?.item_rows ?? []
    })

    function setCartItems(cartItems: InvoiceRow[]) {
      if (!shoppingCart.value) {
        shoppingCart.value = { invoice: { item_rows: [] } }
      }

      // eslint-disable-next-line
      shoppingCart.value.invoice.item_rows = cartItems
    }

    function getCartItemsByParticipantidProducttype(participantId: number, productType: string) {
      const allItems = shoppingCart.value?.invoice?.item_rows ?? []

      // invoiceRowHasParticipantId(invoiceRow: InvoiceRow, participantId: number) {
      const filteredArray = allItems.filter((invoiceRow) => {
        return invoiceRowHasParticipantId(participantId, productType, invoiceRow)
      })

      return filteredArray
    }

    /**
     * Retrieves a specific cart item by participant ID, product type, and program ID.
     *
     * @param {number} participantId - The ID of the participant.
     * @param {string} productType - The type of product.
     * @param {number} productId - The ID of the product.
     *
     * @return {Object | undefined} - The cart item matching the provided participant ID, product type, and program ID. Returns undefined if no matching item is found.
     */
    function getCartItemByParticipantidProgramid(participantId: number, productId: number) {
      const productTypeItems = getCartItemsByParticipantidProducttype(
        participantId,
        PRODUCT_TYPE_PROGRAM
      )
      return productTypeItems.find((item) => item.cart_item?.product === productId)
    }

    /**
     * Retrieves a specific cart item by participant ID, product type, and program ID.
     *
     * @param {number} participantId - The ID of the participant.
     * @param {string} productType - The type of product.
     * @param {number} productId - The ID of the product.
     *
     * @return {Object | undefined} - The cart item matching the provided participant ID, product type, and program ID. Returns undefined if no matching item is found.
     */
    function getCartItemByParticipantidCourseid(participantId: number, productId: number) {
      const productTypeItems = getCartItemsByParticipantidProducttype(
        participantId,
        PRODUCT_TYPE_COURSE
      )
      return productTypeItems.find((item) => item.cart_item?.product === productId)
    }

    function invoiceRowHasParticipantId(
      participantId: number,
      productType: string,
      invoiceRow: InvoiceRow
    ) {
      // refundable is goofy -- it may not exist if we haven't been through the cart validation cycle
      const refundable = invoiceRow.refundable ?? true
      return (
        invoiceRow.cart_item?.participant === participantId &&
        refundable &&
        invoiceRow.cart_item?.product_type === productType
      )
    }

    function cvrFromShoppingCart() {
      const cvr: CartValidationRequest = {}

      cvr.items = shoppingCart.value?.invoice?.item_rows
        ?.filter((item: InvoiceRow) => {
          return !item.credit
        })
        .map((item) => item.cart_item as CartItem)

      return cvr
    }

    function cvrFromInvoice() {
      const cvr: CartValidationRequest = {}

      cvr.items = invoice.value?.invoice?.item_rows
        ?.filter((item: InvoiceRow) => {
          return !item.credit
        })
        .map((item) => item.cart_item as CartItem)

      return cvr
    }

    function getPaymentOptionDescription(option: PaymentOption) {
      // .description +  ?? 'n/a'
      let desc = option.description ?? 'n/a'

      let sum =
        option.provincial_price && option.provincial_price > 0 ? Number(option.provincial_price) : 0
      sum += option.national_price && option.national_price > 0 ? Number(option.national_price) : 0

      if (sum > 0) {
        desc += ' $' + Number(sum).toFixed(2)
      }

      return desc
    }

    function getItemFromCart(idx: number) {
      const items = getCartItems.value
      return items[idx] ?? null
    }

    function getItemCount() {
      return getCartItems.value.length
    }

    function isEmpty(): boolean {
      return getCartItems.value.length === 0
    }

    function removeParticipantProgramFromCart(participantId: number, productId: number) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []
      let newCartItems: InvoiceRow[] = []

      items.forEach(function (item) {
        if (
          !(
            item.cart_item?.product_type === PRODUCT_TYPE_PROGRAM &&
            item.cart_item?.product === productId &&
            item.cart_item?.participant === participantId
          )
        ) {
          newCartItems = [item, ...newCartItems]
        }
      })

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      shoppingCart.value.invoice.item_rows = newCartItems
    }

    function removeParticipantCourseFromCart(participantId: number, productId: number) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []
      let newCartItems: InvoiceRow[] = []

      items.forEach(function (item) {
        if (
          !(
            item.cart_item?.product_type === PRODUCT_TYPE_COURSE &&
            item.cart_item?.product === productId &&
            item.cart_item?.participant === participantId
          )
        ) {
          newCartItems = [item, ...newCartItems]
        }
      })

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      shoppingCart.value.invoice.item_rows = newCartItems
    }

    /**
     * for registrations, need to remove registrations, FOR THIS participant, that arecurrently in the cart as they're
     *   accounted for in the registration add/edit component
     *
     * @param participantId
     */
    function removeParticipantRegistrationsFromCart(participantId: number) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []
      let newCartItems: InvoiceRow[] = []

      items.forEach(function (item) {
        if (
          !(
            item.cart_item?.product_type === PRODUCT_TYPE_REGISTRATIONOPTION &&
            item.cart_item?.participant === participantId
          )
        ) {
          newCartItems = [item, ...newCartItems]
        }
      })

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      shoppingCart.value.invoice.item_rows = newCartItems
    }

    /**
     * simple local cart pre-add validation
     * @param product
     */
    function canAddItemToCart(product: CartItem) {
      // const items = shoppingCart.value?.invoice?.item_rows ?? []

      let foundMatch = false
      if (product?.product_type === PRODUCT_TYPE_COURSE) {
        // CONTENT TEST FOR Course PRODUCTS
        // foundMatch = true
      } else if (product?.product_type === PRODUCT_TYPE_PROGRAM) {
        // CONTENT TEST FOR Program PRODUCTS
        // foundMatch = true
      } else {
        foundMatch = false
      }

      return !foundMatch
    }

    function canReplaceItemInCart(cartIdx: number, product: InvoiceRow) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []

      let foundMatch = false
      if (product.cart_item?.product_type === PRODUCT_TYPE_REGISTRATIONOPTION) {
        const participantId = product.cart_item.participant
        items.forEach(function (item, idx) {
          if (idx !== cartIdx && item.cart_item?.participant === participantId) {
            foundMatch = true
          }
        })
      }
      return !foundMatch
    }

    function addItemToCart(item: InvoiceRow) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []

      // need to check that we don't already have this participant in the cart
      items.push(item)

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      shoppingCart.value.invoice.item_rows = items
    }

    function replaceItemInCart(cartIdx: number, product: InvoiceRow) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      if (items[cartIdx] ?? false) {
        items[cartIdx] = product
      }

      shoppingCart.value.invoice.item_rows = items

      return true
    }

    function deleteItemFromCart(cartIdx: number) {
      const items = shoppingCart.value?.invoice?.item_rows ?? []

      if (!shoppingCart.value || typeof shoppingCart.value.invoice === 'undefined') {
        shoppingCart.value = {}
        shoppingCart.value.invoice = {}
      }

      if (items[cartIdx] ?? false) {
        const delItem = items[cartIdx]
        items.splice(cartIdx, 1)

        items.forEach(function (rowItem, idx) {
          const refundable = rowItem.refundable ?? true
          if (refundable) {
            if (
              rowItem.cart_item?.participant === delItem.cart_item?.participant &&
              rowItem.cart_item?.product_type === delItem.cart_item?.product_type &&
              rowItem.cart_item?.product === delItem.cart_item?.product
            ) {
              items.splice(idx, 1)
            }
          }
        })
      }

      shoppingCart.value.invoice.item_rows = items

      return true
    }

    /**
     * Validates a product in the cart.
     *
     * @param {CartItem} cartItem - The cart item to validate.
     * @returns {boolean} - Returns true if the product is valid, false otherwise.
     */
    async function validateProduct(cartItem: CartItem) {
      let response

      processingCartData.value = true
      try {
        response = await api.api.validateProduct({ data: cartItem })
      } catch (error) {
        // need to return the error structure!
        errors.value = processApiAndCartErrors(error)
        return false
      } finally {
        processingCartData.value = false
      }

      return response.ok
    }

    /**
     * given a cart, populates the invoice object to be displayed, returns invoice errors
     *
     */
    async function validateCart() {
      if (!shoppingCart.value) {
        return [
          {
            message: 'No Cart'
          }
        ]
      }

      invoiceErrors.value = []

      // get a temp cart if we need to rollback
      const tempCart = cvrFromShoppingCart()

      // replace with global.incrementLoading()
      processingCartData.value = true
      try {
        const response = await api.api.validateCart({ data: tempCart })
        if (response.data) {
          // shoppingCart.value = response.data?.data
          invoice.value = response.data?.data
        }
      } catch (error) {
        if (isFetchError(error)) {
          invoice.value = shoppingCart.value
          invoiceErrors.value = processApiAndCartErrors(error)
        }
      }

      return invoiceErrors.value
    }

    async function processCart(payment_ott: string, billingInfo: Billing): Promise<PaymentResult> {
      if (!shoppingCart.value) {
        return {
          status: false,
          documents: false,
          message: 'No cart'
        }
      }

      let success = false
      let paymentMessage = ''
      let documents = false

      // cart contents
      const tempCart = cvrFromInvoice()

      try {
        processingCartData.value = true
        const response = await api.api.purchaseCart({
          data: {
            items: tempCart.items,
            cart_validation_token: invoice.value?.cart_validation_token,
            payment_ott: payment_ott,
            billing: billingInfo
          }
        })

        documents = response.data.data.documents
        success = true
        paymentMessage = 'Thank you for your payment'
      } catch (error) {
        success = false
        paymentMessage = 'We were unable to process your payment'
      } finally {
        processingCartData.value = false
      }

      return {
        message: paymentMessage,
        status: success,
        documents: documents
      }
    }

    return {
      shoppingCart,
      errors,
      invoice,
      invoiceErrors,
      getInvoiceItems,
      resetCart,
      isEmpty,
      processingCartData,
      canAddItemToCart,
      validateProduct,
      canReplaceItemInCart,
      addItemToCart,
      replaceItemInCart,
      deleteItemFromCart,
      getCartItems,
      setCartItems,
      getCartItemsByParticipantidProducttype,
      getCartItemByParticipantidProgramid,
      getCartItemByParticipantidCourseid,
      removeParticipantRegistrationsFromCart,
      removeParticipantProgramFromCart,
      removeParticipantCourseFromCart,
      getItemFromCart,
      getItemCount,
      getPaymentOptionDescription,
      getCartTotals,
      getInvoiceTotals,
      getInvoiceTotal,
      validateCart,
      processCart
    }
  },
  {
    persist: true
  }
)
