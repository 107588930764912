/**
 * Vuetify3 Plugin
 */
import { createVuetify, type VuetifyOptions, type ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import * as labsComponents from 'vuetify/labs/components'
import { VSnackbarQueue } from 'vuetify/labs/VSnackbarQueue'

// Translations provided by Vuetify
import { en } from 'vuetify/locale'

// Misc
// import { loadFonts } from '@/plugins/webfontloader'
// await loadFonts()

// Styles
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import '@/styles/_settings.scss'
import '@/styles/_main.scss'
// $fhbc-green: #0e5d25;
// $fhbc-red: #b91226;
// $fhbc-blue: #1f1377;

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#1f1377',
    secondary: '#b91226',
    background: '#ecf0f1',
    error: '#c0392b',
    info: '#2980b9',
    success: '#27ae60',
    warning: '#f1c40f',
    fhbcGreen: '#0e5d25',
    fhbcBlue: '#1f1377',
    fhbcRed: '#b91226'
  }
}

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#d35400',
    secondary: '#8e44ad',
    background: '#2f3640',
    error: '#c0392b',
    info: '#2980b9',
    success: '#27ae60',
    warning: '#f1c40f',
    fhbcGreen: '#0e5d25',
    fhbcBlue: '#1f1377',
    fhbcRed: '#b91226'
  }
}

/**
 * Vuetify Components
 *
 * @see {@link https://vuetifyjs.com/en/features/treeshaking/}
 */
let vuetifyConfig: VuetifyOptions = {
  // Global configuration
  // https://vuetifyjs.com/en/features/global-configuration/
  /*
  defaults: {
    global: {
      ripple: false,
    },
    VSheet: {
      elevation: 4,
    },
  },
  */
  /*
  // Icon Fonts
  // https://vuetifyjs.com/en/features/icon-fonts/
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  */
  // Internationalization (i18n)
  // https://vuetifyjs.com/en/features/internationalization/#internationalization-i18n
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en }
  },
  // Theme
  // https://vuetifyjs.com/en/features/theme/
  theme: {
    defaultTheme: 'light',
    themes: {
      light: lightTheme,
      dark: darkTheme
    }
  },
  components: {
    VSnackbarQueue
  }
}

if (import.meta.env.DEV) {
  // Disable treeshaking for DEV mode.
  vuetifyConfig = {
    components: { components, labsComponents, VSnackbarQueue },
    directives,
    ...vuetifyConfig
  }
}
export default createVuetify(vuetifyConfig)

// Export for test.
export { components, directives }
