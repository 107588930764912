<script setup lang="ts">
import { RouterView } from 'vue-router'
import { computed, type ComputedRef, onMounted, ref, watch } from 'vue'

import logo from '@/assets/FHBC-logo.svg'

/** Title */
const title = import.meta.env.VITE_APP_TITLE ?? 'Vuetify3 Application'

import { useUserStore } from '@/stores/user'
import { useGlobalStore } from '@/stores/global'
import { useConfigStore } from '@/stores/config'
import { useTheme } from 'vuetify'
import { routerPush } from '@/router'
import { useCartStore } from '@/stores/cart'
import { storeToRefs } from 'pinia'
import type { CurrentPathInfo } from '@/stores/config'
import FooterContent from '@/components/FooterContent.vue'
import NotificationsView from './views/NotificationsView.vue'
import DocumentsView from './views/DocumentsView.vue'
import HelpContent from '@/components/content/HelpContent.vue'

import { useRoute } from 'vue-router'
const route = useRoute()

/** Vuetify Theme */
const theme = useTheme()

/** Config Store */
const configStore = useConfigStore()

/** User / Participant / Account Store **/
const userStore = useUserStore()
const currentUser = storeToRefs(userStore)
const isLoggedIn = ref(currentUser.isLoggedin)

/** Global Store */
const globalStore = useGlobalStore()
const currentGlobalStore = storeToRefs(globalStore)
const sbItems = ref(currentGlobalStore.sbQueueMessages)

/** Cart Store **/
const cartStore = useCartStore()
const cartCount = ref<number>(0)

const documentCount = computed(() => {
  const count = currentUser.dependants.value.reduce((count, dependant) => {
    return count + (dependant.pending?.documents ?? 0)
  }, 0)

  return count + (currentUser.participant.value?.pending?.documents ?? 0)
})

const notificationCount = computed(() => {
  const count = currentUser.dependants.value.reduce((count, dependant) => {
    return count + (dependant.pending?.notifications ?? 0)
  }, 0)
  return count + (currentUser.participant.value?.pending?.notifications ?? 0)
})

const showNotifications = ref(false)
const showDocuments = ref(false)
const showHelp = ref(false)

function toggleShowDocumentsDialog() {
  showNotifications.value = false
  showDocuments.value = true
  showHelp.value = false
}

function toggleShowNotificationsDialog() {
  showNotifications.value = true
  showDocuments.value = false
  showHelp.value = false
}

function toggleShowHelpDialog() {
  showNotifications.value = false
  showDocuments.value = false
  showHelp.value = true
}

const currentRoute = ref<CurrentPathInfo>()
watch(route, () => {
  currentRoute.value = {
    name: route.name,
    path: route.path,
    params: route.params
  }
  // console.log([route, route.name ?? 'no name', route.path, route.params])
})

watch(cartStore, () => {
  cartCount.value = cartStore.getItemCount()
})

function logoutApplicationReset() {
  userStore.logout()
  routerPush('home')
}

/** loading overlay visibility */
// const loading: WritableComputedRef<boolean> = computed({
//   get: () => globalStore.loadingCounter > 0,
//   set: (v) => globalStore.setLoading(v)
// })

const loading = computed({
  get: () => globalStore.loadingCounter > 0,
  set: (v) => globalStore.setLoading(v)
})

/** Toggle Dark mode */
const isDark: ComputedRef<string> = computed(() => (configStore.theme ? 'dark' : 'light'))

onMounted(() => {
  document.title = title

  configStore.getAppMaintenanceStatus(currentRoute.value)
  configStore.loadBaseData()
  configStore.loadAppData()

  setInterval(
    async () => {
      // check current app status
      configStore.getAppMaintenanceStatus(currentRoute.value)
    },
    1000 * 60 * 5
  )
})

function navigateToLogin() {
  routerPush('login')
}
</script>
<template>
  <v-layout :theme="isDark" class="rounded rounded-md">
    <v-app-bar :elevation="2">
      <v-container>
        <v-row>
          <div class="d-flex align-center justify-space-between w-100">
            <v-avatar class="cursor-pointer m-4 logo" @click="routerPush('home')" :tile="true">
              <img :src="logo" alt="logo" />
            </v-avatar>

            <v-app-bar-title class="m-4"
              ><span class="mx-2">FHBC Registration Portal</span></v-app-bar-title
            >

            <v-btn icon="mdi-help-circle" @click="toggleShowHelpDialog"> </v-btn>

            <v-btn-group v-if="isLoggedIn">
              <v-btn v-if="cartCount ?? false" @click="routerPush('cart')">
                <v-badge :content="cartCount">
                  <v-icon icon="mdi-cart" size="x-large"></v-icon>
                </v-badge>
              </v-btn>

              <!-- todo @tcropley icons should be white on green background, badge with #s should be circle red # should be white -->
              <v-btn @click="toggleShowNotificationsDialog()">
                <v-badge :content="notificationCount" v-if="notificationCount > 0">
                  <v-icon icon="mdi-message" size="x-large"></v-icon>
                </v-badge>
                <v-icon v-else icon="mdi-message" size="x-large"></v-icon>
              </v-btn>

              <NotificationsView v-model:modalValue="showNotifications"></NotificationsView>

              <v-btn @click="toggleShowDocumentsDialog()">
                <v-badge :content="documentCount" v-if="documentCount > 0">
                  <v-icon icon="mdi-file-document-edit-outline" size="x-large"></v-icon>
                </v-badge>
                <v-icon v-else icon="mdi-file-document-edit-outline" size="x-large"></v-icon>
              </v-btn>

              <DocumentsView v-model:modalValue="showDocuments"></DocumentsView>

              <v-btn icon="mdi-logout" @click="logoutApplicationReset"></v-btn>
            </v-btn-group>

            <v-btn v-if="!isLoggedIn" icon="mdi-account" @click="navigateToLogin"> </v-btn>
          </div>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main class="d-flex align-center justify-center" style="width: 600px; min-height: 300px">
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component :is="Component" :key="route.name" />
        </transition>
      </router-view>
    </v-main>

    <v-snackbar-queue v-model:modelValue="sbItems">
      <template v-slot:actions>
        <v-btn icon="mdi-close-circle"></v-btn>
      </template>
    </v-snackbar-queue>

    <v-footer app elevation="3">
      <FooterContent />
    </v-footer>
  </v-layout>
  <teleport to="head">
    <meta name="theme-color" :content="theme.computedThemes.value['light'].colors.background" />
    <link rel="icon" :href="logo" type="image/svg+xml" />
  </teleport>

  <HelpContent v-model:modalValue="showHelp" />

  <!--    <v-overlay v-model="loading" :persistent="true" app class="justify-center align-center">-->
  <v-overlay v-model="loading" class="justify-center align-center">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<style lang="scss">
// @use 'vuetify/_settings';
@import '@/styles/style';

.v-avatar.logo {
  align-items: unset !important;
}

.v-btn-group {
  background-color: transparent;
  .v-btn {
    color: white;
    background-color: transparent;
  }
  .v-badge {
    color: white;
  }

  v-icon {
    background-color: $fhbc-green;
    color: white;
  }
  .v-badge__badge.v-theme--light {
    background-color: $fhbc-red;
    color: white;
  }
}

.v-toolbar__content {
  background-color: $fhbc-green;
  color: white;
}
</style>
