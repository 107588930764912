import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { Account, AuthStruct, Participant, ParticipantNotification } from '@/services/api'
import type { Document } from '@/services/api'
// clearing pinia stores:
// https://github.com/vuejs/pinia/discussions/911

export const useUserStore = defineStore(
  'user',
  () => {
    const account = ref<Account>()
    const auth = ref<AuthStruct>()

    // Account information
    const isLoggedin = computed(() => !!account.value)

    const participant = computed(() => {
      return account.value?.participant
    })
    const haveParticipant = computed(() => !!account.value?.participant)

    const dependants = computed(() => {
      return account.value?.dependants ?? []
    })
    const haveDependants = computed(() => {
      const dependants = account.value?.dependants ?? []
      return dependants.length > 0
    })

    function updateUser(userData?: Account | null) {
      if (userData) {
        account.value = userData
      } else {
        // api.setSecurityData(null)
        account.value = undefined
      }
    }

    function email() {
      return account.value?.email
    }

    /**
     * Get the account ID.
     *
     * @returns {string|undefined} The ID of the account. Returns undefined if account value is null or undefined.
     */
    function accountId() {
      return account.value?.id
    }

    function fullname() {
      return account.value?.first_name + ' ' + account.value?.last_name
    }

    /**
     *
     * @param participant
     */
    function updateParticipantOrDependant(participant: Participant) {
      if (account.value) {
        // are we updating the participant record
        if (account.value.participant?.id === participant.id) {
          refreshParticipant(participant)
          return
        }
        // or are we updating a dependant
        const dependants = account.value.dependants ?? []
        if (dependants.length) {
          const index = dependants.findIndex((d) => d.id === participant.id)
          if (index !== -1) {
            dependants[index] = participant
            account.value.dependants = dependants
          } else {
            console.error('Trying to update a participant not found in this account')
          }
        }
      }
    }

    /**
     * get the participant (either own or dependant) by id
     * @param {Number} participantId
     */
    function getParticipantById(participantId: Number) {
      if (!account.value || participantId === 0) {
        return null
      }

      if (participantId == account.value?.participant?.id) {
        return account.value?.participant
      }

      const dependants = account.value.dependants ?? []
      return dependants.find((d) => d.id == participantId)
    }

    function setNotificationToRead(notification: ParticipantNotification) {
      if (!account.value) {
        return
      }

      const notifiedParticipant = getParticipantById(notification.participant ?? 0)

      if (notifiedParticipant) {
        const unreadNotifications = notifiedParticipant.new_notifications ?? []
        const notificationIndex = unreadNotifications.findIndex((n) => n.id === notification.id)

        if (notificationIndex === -1) {
          return
        }
        unreadNotifications.splice(notificationIndex, 1)
        notification.read_at = new Date().toISOString()
        notifiedParticipant.old_notifications?.unshift(notification)

        if (notifiedParticipant.pending?.notifications) {
          notifiedParticipant.pending.notifications = Math.max(
            notifiedParticipant.pending.notifications - 1,
            0
          )
        }
      }
    }

    function updateDocumentsToSignCount(documents: Document[]) {
      const documentsToSignCount = new Map<number, number>()

      if (participant.value) {
        documentsToSignCount.set(participant.value.id, 0)
      }

      dependants.value.forEach((participant: Participant) => {
        documentsToSignCount.set(participant.id, 0)
      })

      documents.forEach((document: Document) => {
        if (!document.signed_by) {
          const count = documentsToSignCount.get(document.participant) || 0
          documentsToSignCount.set(document.participant, count + 1)
        }
      })

      documentsToSignCount.forEach((count, participantId) => {
        const notifiedParticipant = getParticipantById(participantId)
        if (notifiedParticipant) {
          notifiedParticipant.pending.documents = count
        }
      })
    }

    /**
     * set the participant record for this account // refresh participant attributes
     * @param {Participant} participant
     */
    function refreshParticipant(participant: Participant) {
      if (account.value) {
        account.value.participant = participant
      }
    }

    /**
     * Refreshes the dependant of a participant.
     *
     * @param {Participant} dependant - The dependant to be refreshed.
     */
    function refreshDependant(dependant: Participant) {
      if (account.value) {
        const dependants = account.value.dependants ?? []
        const index = dependants.findIndex((d) => d.id === dependant.id)
        if (index !== -1) {
          dependants[index] = dependant
        } else {
          dependants.push(dependant)
        }
        account.value.dependants = dependants
      }
    }

    // authorization information
    function triggerRefreshCalc(): Boolean {
      const now = Date.now()

      const expiryTime = auth.value?.expiry_ts ?? 0
      const timeLeft = expiryTime - now

      return timeLeft < 60000 // Refresh if token expires in less than 1 minute
    }

    function getRefreshToken() {
      // this is deprecated ...
      return auth.value?.access_token
    }

    function getAccessToken() {
      return auth.value?.access_token
    }

    function updateAuth(authData?: AuthStruct | null) {
      if (authData) {
        const now = Date.now()
        authData.expiry_ts = Number(now) + Number(authData?.expires_in) * 1000
        auth.value = authData
      } else {
        // api.setSecurityData(null)
        auth.value = undefined
      }
    }

    function logout() {
      auth.value = undefined
      account.value = undefined
    }

    return {
      account,
      email,
      fullname,
      accountId,
      isLoggedin,
      updateUser,
      participant,
      getParticipantById,
      haveParticipant,
      dependants,
      haveDependants,
      refreshParticipant,
      refreshDependant,
      updateParticipantOrDependant,
      setNotificationToRead,
      updateDocumentsToSignCount,

      auth,
      logout,
      updateAuth,
      getAccessToken,
      getRefreshToken,
      triggerRefreshCalc
    }
  },
  {
    persist: true
  }
)
