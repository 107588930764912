<script setup lang="ts">
import { ref, watch } from 'vue'
import { useGlobalStore } from '@/stores/global'
import { useDocuSign } from '@/composable/useDocuSign'
import type { Document } from '@/services/api'

const props = defineProps<{
  document: Document | null
}>()

const displayModal = ref(false)
const globalStore = useGlobalStore()
const { signDocument, loadDocuSignScript, signingStarted } = useDocuSign()
const emit = defineEmits(['signingConcluded'])

watch(
  () => props.document,
  async (newDocument) => {
    if (newDocument) {
      displayModal.value = true
      try {
        await signDocument(newDocument)
      } catch (error) {
        console.error('Error processing document:', error)
      }
    }
  }
)

watch(signingStarted, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    closeModal()
  }
})

const closeModal = () => {
  displayModal.value = false
  if (signingStarted.value) {
    signingStarted.value = false
    globalStore.addSnackbarMessageToQueue('Document signing cancelled', 'error')
  }
  emit('signingConcluded')
}

await loadDocuSignScript()
</script>

<template>
  <v-dialog id="docuSignModal" class="docu-sign-modal" v-model="displayModal">
    <v-card align="center" justify="center">
      <v-card-title>
        <span class="headline">{{ document?.signed_by ? 'View Document' : 'Sign Document' }}</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-progress-linear
            v-if="globalStore.isLoading()"
            indeterminate
            color="fhbcGreen"
            class="mb-4"
          ></v-progress-linear>
          <div id="signing-ceremony" class="mx-auto"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="fhbcRed" @click="closeModal">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#signing-ceremony {
  height: 70vh;
  width: 80%;
}

.docu-sign-modal .v-dialog {
  min-height: 80vh;
}

.docu-sign-modal .v-card {
  height: 100%;
}
</style>
