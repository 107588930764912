export const CONFIG = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL || 'this-place',
  API_TOKEN_STORAGE_KEY: import.meta.env.VITE_API_TOKEN_STORAGE_KEY || '',
  PYMNT_INTERPAY_PATH: import.meta.env.VITE_INTERPAY_PATH || '',
  DOCUSIGN_CLIENTID: import.meta.env.VITE_API_DOCUSIGN_CLIENTID || '',
  DOCUSIGN_JS_URL: import.meta.env.VITE_API_DOCUSIGN_JS_URL || '',
  RUNNING_IN_ENV: import.meta.env.VITE_RUNNING_IN_ENVIRONMENT || 'development',
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || '',
  SITE_ASSET_ROOT_PATH:
    import.meta.env.VITE_SITE_ASSET_ROOT_PATH || 'https://assets.fieldhockeybc.ca/fhbc'
}
