import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

import type {
  Club,
  ConfigurationVariable,
  League,
  Lookup,
  Program,
  RegistrationOption
} from '@/services/api'
import { api } from '@/services'
import type { RouteRecordNameGeneric, RouteParamsGeneric } from 'vue-router'
import router from '@/router'

export interface CurrentPathInfo {
  name: RouteRecordNameGeneric
  path: string
  params: RouteParamsGeneric
}

/** Config Store */
export const useConfigStore = defineStore(
  'config',
  () => {
    const haveAppData: Ref<boolean> = ref(false)
    const haveBaseData: Ref<boolean> = ref(false)
    const haveProductData: Ref<boolean> = ref(false)

    const loadingProductData: Ref<boolean> = ref(false)
    const loadingBaseData: Ref<boolean> = ref(false)
    const loadingAppData: Ref<boolean> = ref(false)

    /* basic application config options */
    const fhbcConfig: Ref<ConfigurationVariable[] | undefined> = ref()
    const activeYear: Ref<string> = ref('')

    // - basic lookups:
    const genders: Ref<Lookup[] | undefined> = ref()
    const provinces: Ref<Lookup[] | undefined> = ref()
    const languages: Ref<Lookup[] | undefined> = ref()
    const paymentMethods: Ref<Lookup[] | undefined> = ref()

    const clubs: Ref<Club[] | undefined> = ref()
    const leagues: Ref<League[] | undefined> = ref()
    const haveAppProducts: Ref<boolean> = ref(false)
    const registrationOptions: Ref<RegistrationOption[]> = ref([])
    const programOptions: Ref<Program[]> = ref([])
    const courseOptions: Ref<Program[]> = ref([])

    // 1800 seconds is 1/2 an hour
    const maxSecondBetweenDataLoads = 1800

    // - programs
    // - courses

    /** Dark Theme mode */
    const theme: Ref<boolean> = ref(window.matchMedia('(prefers-color-scheme: dark)').matches)
    const locale: Ref<string> = ref(window.navigator.languages[0] ?? window.navigator.language)
    const dataLastLoaded: Ref<number> = ref(0)
    const baseLastLoaded: Ref<number> = ref(0)
    const productLastLoaded: Ref<number> = ref(0)

    /** Toggle Dark/Light mode */
    const toggleTheme = () => (theme.value = !theme.value)
    /**
     * Set Locale.
     *
     * @param locale - Locale
     */
    const setLocale = (l: string) => (locale.value = l)

    const appUnderMaintenance: Ref<boolean> = ref(false)

    async function getAppMaintenanceStatus(currentRoute: CurrentPathInfo | undefined) {
      try {
        const currentAppStatus = await api.api.getStatus()
        if (currentAppStatus.data.data?.value != undefined) {
          const statusStr = currentAppStatus.data.data.value
          setAppUnderMaintenance(statusStr, currentRoute)
          //setAppUnderMaintenance('maintenance')
        }
      } catch (error) {
        console.log(error) // rejectReason of any first rejected promise
      }
    }

    function setAppUnderMaintenance(
      status: string,
      currentRoute: CurrentPathInfo | undefined
    ): void {
      // off, maintenance
      if (status === 'maintenance' || status === 'off') {
        appUnderMaintenance.value = true
        if (currentRoute && currentRoute.name !== 'maintenance') {
          router.push({ name: 'maintenance', query: { prev: currentRoute.path } })
        }
      } else {
        appUnderMaintenance.value = false
      }
    }

    /**
     * Loads the application's products data if it hasn't been loaded recently.
     * The max time between data loads is determined by the value of 'maxSecondBetweenDataLoads'.
     *
     * @returns {Promise<void>} - Resolves when the products data has been successfully loaded, or rejects with an error if loading fails.
     */
    async function loadAppProducts() {
      const now = Date.now()

      if (
        productLastLoaded.value &&
        productLastLoaded.value - now < maxSecondBetweenDataLoads * 1000
      ) {
        return
      }

      const registrationResponse = api.api.getAllRegistrationOptions()
      const programResponse = api.api.getAllPrograms()
      const courseResponse = api.api.getAllCourses()

      const allPromise = Promise.all([registrationResponse, programResponse, courseResponse])

      try {
        loadingProductData.value = true
        haveProductData.value = false

        const [resolveRegistrationResponse, resolveProgramResponse, resolveCourseResponse] =
          await allPromise

        registrationOptions.value = resolveRegistrationResponse.data?.data ?? []
        programOptions.value = resolveProgramResponse.data?.data ?? []
        courseOptions.value = resolveCourseResponse.data?.data ?? []

        haveProductData.value = true
        productLastLoaded.value = Date.now()
      } catch (error) {
        console.log(error) // rejectReason of any first rejected promise
        haveProductData.value = false
      } finally {
        loadingProductData.value = false
      }
    }

    async function loadBaseData() {
      const now = Date.now()

      if (baseLastLoaded.value && baseLastLoaded.value - now < maxSecondBetweenDataLoads * 1000) {
        return
      }

      const allLookupsResponse = api.api.getLookups()
      const fhbcConfigResponse = api.api.getConfigurationVariables()

      const allPromise = Promise.all([allLookupsResponse, fhbcConfigResponse])

      try {
        loadingBaseData.value = true
        haveBaseData.value = false

        const [resolveAllLookupsResponse, resolveAllFhbcConfigResponse] = await allPromise

        fhbcConfig.value = resolveAllFhbcConfigResponse.data?.data
        if (fhbcConfig.value) {
          const aYear = fhbcConfig.value.find((item) => {
            return item.key === 'active_year'
          })
          activeYear.value = aYear?.value.toString() ?? ''
        }

        const allLookups = resolveAllLookupsResponse.data?.data
        if (allLookups) {
          for (const lookupGroup of allLookups) {
            if (lookupGroup.name == 'Gender') {
              genders.value = lookupGroup.lookups
            } else if (lookupGroup.name == 'Province') {
              provinces.value = lookupGroup.lookups
            } else if (lookupGroup.name == 'Language') {
              languages.value = lookupGroup.lookups
            } else if (lookupGroup.name == 'Payment Method') {
              paymentMethods.value = lookupGroup.lookups
            }
          }

          haveBaseData.value = true
          baseLastLoaded.value = Date.now()
        }
      } catch (error) {
        console.log(error) // rejectReason of any first rejected promise
        haveBaseData.value = false
      } finally {
        loadingBaseData.value = false
      }
    }

    /**
     * Loads the application data from the API.
     *
     * If the data was loaded within a certain time limit, it will not be reloaded.
     *
     * @returns {Promise<void>} Returns a promise that resolves when the data is loaded, or rejects if there is an error.
     */
    async function loadAppData() {
      const now = Date.now()

      if (dataLastLoaded.value && dataLastLoaded.value - now < maxSecondBetweenDataLoads * 1000) {
        return
      }

      const clubResponse = api.api.getAllClubs()
      const leagueResponse = api.api.getAllLeagues()

      const allPromise = Promise.all([clubResponse, leagueResponse])

      try {
        loadingAppData.value = true
        haveAppData.value = false

        const [resolveClubResponse, resolveLeagueResponse] = await allPromise

        clubs.value = resolveClubResponse.data?.data
        leagues.value = resolveLeagueResponse.data?.data

        haveAppData.value = true
        dataLastLoaded.value = Date.now()
      } catch (error) {
        console.log(error) // rejectReason of any first rejected promise
        haveAppData.value = false
      } finally {
        loadingAppData.value = false
      }
    }

    return {
      theme,
      toggleTheme,
      setLocale,

      haveAppProducts,
      loadAppProducts,
      loadingProductData,

      haveAppData,
      loadAppData,
      loadingAppData,

      haveBaseData,
      loadBaseData,
      loadingBaseData,

      genders,
      provinces,
      leagues,
      clubs,
      registrationOptions: registrationOptions,
      programOptions: programOptions,
      courseOptions: courseOptions,
      fhbcConfig,
      activeYear,

      getAppMaintenanceStatus,
      appUnderMaintenance
    }
  },
  {
    persist: true
  }
)
