import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { isArray } from 'lodash'

interface SnackbarMessage {
  id: string
  text: string
  color: string
  closeOnContentClick: boolean
  timer: string
  location: string
  multiline: boolean
}

/** Global Store */
export const useGlobalStore = defineStore('global', () => {
  // export default defineStore('global', () => {
  //   // State

  /** Reworking loading overlay to use a counter instead of a flag ***/
  const loadingCounter: Ref<number> = ref(0)
  /** Loading overlay */
  const loading: Ref<boolean> = ref(true)
  /** ProgressBar Percentage */
  const progress: Ref<number | null> = ref(null)
  /** SnackBar Text */
  const message: Ref<string> = ref('')
  const sbQueueMessages: Ref<SnackbarMessage[]> = ref([]) // Ref<{ id: string, text: string, color: string }[]> = ref([])

  /**
   * get a message type for the snackbar
   * @param color
   */
  function getMessageTypeColour(color: string) {
    switch (color.toLowerCase()) {
      case 'success':
      case 'info':
      case 'warning':
      case 'error':
        return color.toLowerCase()

      default:
        return 'info'
    }
  }

  /**
   * Show loading Overlay
   *
   * @param display - visibility
   */
  function setLoading(display: boolean): void {
    loading.value = display
    if (!display) {
      // Reset Progress value
      progress.value = null
    }
  }

  function isLoading() {
    return loadingCounter.value > 0
  }

  function incrementLoading() {
    loadingCounter.value++
  }

  function decrementLoading() {
    loadingCounter.value--
  }

  function addSnackbarMessageToQueue(message: string | string[], messageType: string) {
    let isMultiline: boolean = false
    if (isArray(message)) {
      message = message.join('\n')
      isMultiline = true
    }
    sbQueueMessages.value.push({
      id: 'id_' + Date.now(),
      text: message,
      color: getMessageTypeColour(messageType),
      closeOnContentClick: true,
      timer: '#cccccc',
      location: 'top center',
      multiline: isMultiline
    })
  }

  /**
   * Update progress value
   *
   * @param v - progress value
   */
  function setProgress(v: number | null = null): void {
    // update progress value
    progress.value = v
    // display loading overlay
    loading.value = true
  }

  /**
   * Show snackbar message
   *
   * @param msg - snackbar message
   */
  function setMessage(msg: string = ''): void {
    // put snackbar text
    message.value = msg
  }

  return {
    loading,
    loadingCounter,
    progress,
    message,
    setLoading,
    setProgress,
    setMessage,
    isLoading,
    incrementLoading,
    decrementLoading,

    sbQueueMessages,
    addSnackbarMessageToQueue
  }
})
