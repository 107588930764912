<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import NotificationView from '@/components/helpers/NotificationView.vue'
import type { Participant } from '@/services/api'

interface Props {
  modalValue: boolean
}

const props = defineProps<Props>()
let dialog = props.modalValue ?? true
const emit = defineEmits(['update:modalValue'])

const userStore = useUserStore()

const participants = computed(() => {
  const participantsArray = [...userStore.dependants] // Clone the array using the spread operator
  if (userStore.participant) {
    participantsArray.unshift(userStore.participant)
  }
  return participantsArray
})

const tab1 = ref()
const tab2 = ref()

watch(
  () => props.modalValue,
  (newVal) => {
    dialog = newVal
  }
)

watch(tab1, (newVal) => {
  const participant = participants.value.find((p) => p.id === newVal)
  tab2.value =
    participant?.new_notifications && participant?.new_notifications.length > 0 ? 'one' : 'two'
})

function itemProps(item: Participant) {
  return {
    title: (item.preferred_name ?? item.first_name) + ' ' + item.last_name,
    subtitle: (item.pending?.notifications ?? 0).toString() + ' Unread notification(s)'
  }
}

function close() {
  emit('update:modalValue', false)
}

onMounted(() => {
  const selectedParticipant = participants.value.find((item) => {
    return (item.pending?.notifications ?? 0) > 0
  })

  if (participants.value.length > 0) {
    tab1.value = selectedParticipant ? selectedParticipant.id : participants.value[0].id
  }
})

// get a list of members
// for each member get a list of notifications
</script>
<template>
  <v-dialog v-model="dialog" class="dialog-modal">
    <v-container>
      <v-card class="mx-auto px-6 py-8 dialog-card">
        <v-card-title class="text-center justify-center bg-fhbc-green py-6">
          <h2 class="color:white">Notifications</h2>
        </v-card-title>

        <div class="d-flex flex align-content-center mx-3">
          <v-select
            label="Participant"
            :items="participants"
            :item-props="itemProps"
            item-value="id"
            v-model="tab1"
            variant="outlined"
          />
        </div>

        <v-window v-model="tab1">
          <v-window-item
            v-for="(participant, idx) in participants"
            :key="idx"
            :value="participant.id"
          >
            <v-card>
              <v-btn-toggle v-model="tab2" mandatory class="d-flex justify-space-between">
                <v-btn
                  value="one"
                  class="full-width-btn"
                  :class="{ 'active-tab': tab2 === 'one', 'inactive-tab': tab2 !== 'one' }"
                >
                  Unread
                </v-btn>
                <v-btn
                  value="two"
                  class="full-width-btn"
                  :class="{ 'active-tab': tab2 === 'two', 'inactive-tab': tab2 !== 'two' }"
                >
                  Read
                </v-btn>
              </v-btn-toggle>

              <v-window v-model="tab2">
                <v-window-item value="one">
                  <v-card class="px-4">
                    <v-card-text>
                      <template
                        v-if="
                          participant?.new_notifications &&
                          participant?.new_notifications.length > 0
                        "
                      >
                        <NotificationView
                          v-for="(n, idx) in participant?.new_notifications"
                          :key="idx"
                          :notification="n"
                        ></NotificationView>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <v-window-item value="two">
                  <template
                    v-if="
                      participant?.old_notifications && participant?.old_notifications.length > 0
                    "
                  >
                    <NotificationView
                      v-for="(n, idx) in participant?.old_notifications"
                      :key="idx"
                      :notification="n"
                    ></NotificationView>
                  </template>
                </v-window-item>
              </v-window>
            </v-card>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="close()"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>
<style scoped lang="scss">
@import '@/styles/style';
</style>
